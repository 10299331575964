import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateDoctor } from "../dependiencies/action"

function AddExperince(props) {
  const { handleClose, show } = props;
  const { user } = useSelector((state) => state.login);
  // const [form, setForm] = useState({});
  const [error, setError] = useState({});

  const handleInputChange = (name, value) => {
    setAbout({ ...about, [name]: value });
  };
  const [about, setAbout] = useState([
    {
      aboutMe: '',
    }
  ]);


  const dispatch = useDispatch();
  const handleSave = (e) => {
    e.preventDefault();
    // let validate = validator();
    // if (!isEmpty(validate)) {
    //   return false
    // }
    dispatch(updateDoctor({
      about: about.about,
    })).then(() => {
      setAbout({
        aboutMe: "",
      });
      handleClose(false)
    }).catch((error) => {
      console.error("Error updating doctor:", error);
    });
  };


  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={(e) => handleClose(false)}
        backdrop="static"
      >
        <Modal.Header>
          <span>About Me</span>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="">
              <div className="row">
                <div className="mt-3">
                  <label className="focus-label">about Me</label>
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      value={about.about}
                      name="about"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleClose(false)}>
            Close
          </button>
          <button
            className="btn btn-sm btn-success"
            onClick={(e) => handleSave(e)}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddExperince;

import api from "../../../dependencies/utils/Api";

import { CAREER } from "../../../constants/ApplicationUrl";

export const CareerService = {
    async addCareerItem(params) {
        const response = await api.POSTDATA(CAREER, params)
        console.log(response, "response");
        const { data: { error } = {}, status, message } = response
        if (status) {
            return { message, status, error }
        } else {
            return { message, status, error }
        }

    },

}
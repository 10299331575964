import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import TableColumn from "./dependencies/TableColumn";
import { getWithdrawList, withdraw, deletewithdraw, updatewithdraw } from "./dependencies/action";
import { ROLES } from "../../constants/role";
import { storage } from "../../dependencies/store/storage";
import WithdrawAmmount from "./AddUpdate/withdrawAmmount";
import { updateCallStatus } from "../Appointment/dependiencies/action";
import { toDate } from "date-fns";

function Teams() {
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
    fromDate: "",
    toDate: "",
    status: "",
    type: ""

  });
  const { WithdrawList, WithdrawTotalPage } = useSelector((state) => state.Withdraw);
  const dispatch = useDispatch();
  const [showAddUpdate, setShowAddUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')
  const role = storage.getUserRole();

  // console.log(searchQuery);
  // console.log(query)
  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };
  const handleSearch = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };
  useEffect(() => {
    dispatch(getWithdrawList(query));
  }, [dispatch, query]);

  const handleAdd = () => {
    dispatch(withdraw()).then(res => {
      console.log(res)
      if (res.status) {
        setShowAddUpdate(false);
      }
    })
    setShowAddUpdate(true);
  };


  const handleDelete = (id) => {
    dispatch(deletewithdraw(id)).then(res => {
      dispatch(getWithdrawList(query));
    })
  }

  const handleUpdate = (id, status) => {
    if (status === "reject") {
      dispatch(updateCallStatus(id));
    }
    let updateStatus = dispatch(updatewithdraw(id, { status: status }));
    updateStatus.then((res) => {
      if (res.status) {
        dispatch(getWithdrawList(query));
      }
    });
  };
  const handleClearFilters = () => {
    setQuery({
      ...query,
      fromDate: "",
      toDate: "",
      status: "",
      type: ""

    });
  };
  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Withdraw</h4>
            </div>
            {
              (role === ROLES.FRANCHISE || role === ROLES.DOCTOR) &&
              <>
                <div
                  className="col-sm-8 col-9 text-right m-b-20"
                  style={{ textAlign: "end" }}>
                  <a
                    href="#0"
                    className="btn btn-sm btn-success"
                    onClick={() => setShowAddUpdate(true)}>
                    Withdraw
                  </a>
                </div>
              </>
            }
          </div>
          <div className="row m-b-20">
            <div className="form-group col-md-3 mt-3">
              <label>From Date</label>
              <input
                type="date"
                name="fromDate"
                className="form-control"
                value={query.fromDate}
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>To Date</label>
              <input
                type="date"
                name="toDate"
                className="form-control"
                value={query.toDate}
                min={query.fromDate}
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By Staus</label>
              <select
                className="form-select"
                value={query.status}
                onChange={(e) => setQuery({ ...query, status: e.target.value })}
              >
                <option value="">--Select Status--</option>
                <option value="">All</option>
                <option value="Inprocess">Inprocess</option>
                <option value="Credited successfully">Credited</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            {/* <div className="form-group col-md-3 mt-3">
                <label>Search By Staus</label>
                <select
                  className="form-select"
                  value={query.type}
                  onChange={(e) => setQuery({ ...query, type: e.target.value })}
                  >
                  <option value="">--Select Status--</option>
                  <option value="">All</option>
                  <option value="FRANCHISE">Franchise</option>
                  <option value="DOCTOR">Doctor</option>
                </select>
              </div> */}
            <div className="col-4">
              <button
                className="btn btn-sm btn-secondary"
                style={{ marginTop: "27px", marginRight: "10px" }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear Filters
              </button>
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        handleDelete,
                        handleUpdate,
                        pageNo: query.page,
                        size: query.size,
                      })}
                      dataTable={WithdrawList}
                      totalRecord={WithdrawTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WithdrawAmmount
        show={showAddUpdate}
        handleClose={() => setShowAddUpdate()}
        handleAdd={() => handleAdd()}
      />
    </>
  );
}

export default Teams;

import api from "../../../dependencies/utils/Api";

import { GET_FEEDBACK, DELETE_FEEDBACK } from "../../../constants/ApplicationUrl";

export const Feedback = {

    getFeedbackList(params) {
        return api.GET(`${GET_FEEDBACK}?page=${params.page}&size=${params.size}&name=${params.name}&mobile=${params.mobile}&email=${params.email}&fromDate=${params.fromDate}&toDate=${params.toDate}`)
            .then(response => {
                console.log("res--", response);
                const { data: { message, data, totalCount } = {}, status } = response;
                if (status) {
                    return { status, message, data, totalCount };
                } else {
                    return { message, status };
                }
            })
            .catch(error => {
                console.error('Error in getItemList:', error);
                return { message: 'Error: Request failed', status: false };
            });
    },


    deleteFeedback(id) {
        return api.DELETE(`${DELETE_FEEDBACK}/${id}`).then((response) => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {

            }
        })
    },


}
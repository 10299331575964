import { Modal } from "react-bootstrap";
import Api from "../../../dependencies/utils/Api";
import React, { useState, useEffect } from "react";
import { GET_PRESCRIPTION_BY_APPOINTMENT } from "../../../constants/ApplicationUrl";

function AppointmentModal({ show, handleClose, appointment }) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  console.log(appointment, "appoi")
  const [prescription, setPrescription] = useState(null);


  useEffect(() => {
    if (show && appointment) {
      fetchPrescriptionData();
    }
  }, [show, appointment]);

  const fetchPrescriptionData = () => {
    Api.GET(
      `${GET_PRESCRIPTION_BY_APPOINTMENT}/${appointment._id}`
    )
      .then((response) => {
        if (response && response.data) {
          setPrescription(response.data);
        } else {
          setPrescription(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching prescription data: ", error);
        setPrescription(null);
      });
  };
  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Appointment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="doctor-details">
            <div className="row" style={{ background: "#f9f9f9", borderRadius: "5px", border: "1px solid #ddd", marginBottom: "20px", padding: "20px" }}>
              <div className="col-md-6">
                <p>
                  <strong className="px-2">Patient Name:</strong> {appointment?.patientName || ""}
                </p>
                <p>
                  <strong className="px-2">Patient Age:</strong>{" "}
                  {` ${appointment?.age === 0 || appointment?.age === undefined
                    ? ""
                    : `${appointment?.age} yr `
                    }${appointment?.months == 0 || appointment?.months === undefined
                      ? ""
                      : appointment?.age > 0 || appointment?.months > 0
                        ? `${appointment?.age > 0 ? " " : ""}${appointment?.months
                        } month`
                        : ""
                    } `}
                </p>
                <p>
                  <strong className="px-2">Gender:</strong> {appointment?.gender || ""}
                </p>
                <p>
                  <strong className="px-2">Patient Mobile Number:</strong> {appointment?.expert.mobile || ""}
                </p>
                <p>
                  <strong className="px-2">Date Of Appointment:</strong>{" "}
                  {appointment && appointment.dateTime
                    ? `${new Date(appointment.dateTime).toLocaleDateString(
                      "en-GB",
                      options
                    )} -${appointment.selectedTimeSlot}`
                    : ""}
                </p>

                <p>
                  <strong className="px-2">Height:</strong>{" "}
                  {appointment?.height && appointment.heightUnit
                    ? `${appointment.height} ${appointment.heightUnit} `
                    : ""}
                </p>

                <p>
                  <strong className="px-2">Weight:</strong>{" "}
                  {appointment?.weight ? `${appointment.weight} kg ` : ""}
                </p>

                <p>
                  <strong className="px-2">BMI:</strong>{" "}
                  {appointment?.bmi ? `${appointment.bmi} ` : ""}
                </p>

                <p>
                  <strong className="px-2">BP mm of Hg:</strong>{" "}
                  {appointment?.bp ? `${appointment.bp} ` : ""}
                </p>
                <p>
                  <strong className="px-2">Pulse:</strong>{" "}
                  {appointment?.pulse ? `${appointment.pulse} Per min` : ""}
                </p>

                <p>
                  <strong className="px-2">Body Temperature:</strong>{" "}
                  {appointment?.bodyTemperature
                    ? `${appointment.bodyTemperature} ℉`
                    : ""}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong className="px-2">Respiratory rate(RR):</strong>{" "}
                  {appointment && appointment.respiratoryRate
                    ? `${appointment.respiratoryRate} Per min`
                    : ""}
                </p>

                <p>
                  <strong className="px-2">SpO2 %:</strong>{" "}
                  {appointment && appointment.oxigne
                    ? `${appointment.oxigne} %`
                    : ""}
                </p>

                <p>
                  <strong className="px-2">Fasting Blood Sugar(FBS):</strong>{" "}
                  {appointment && appointment.suger1
                    ? `${appointment.suger1} mg/dL`
                    : ""}
                </p>

                <p>
                  <strong className="px-2">Random Blood Sugar(RBS) mg/dL:</strong>{" "}
                  {appointment && appointment.suger2
                    ? `${appointment.suger2} mg/dL`
                    : ""}
                </p>

                <p>
                  <strong className="px-2">Postprandial Blood Sugar(PPBS) mg/dL:</strong>{" "}
                  {appointment && appointment.suger3
                    ? `${appointment.suger3} mg/dL`
                    : ""}
                </p>

                <p>
                  <strong className="px-2">Appointment Mode:</strong> {appointment?.mode || ""}
                </p>
                <p>
                  <strong className="px-2">Payment Status:</strong>{" "}
                  {appointment?.paymentStatus || ""}
                </p>
                <p>
                  <strong className="px-2">Appointment Charges:</strong>{" "}
                  {appointment?.appointmentCharge || ""}
                </p>

                <p>
                  <strong className="px-2">Appointment Status:</strong>
                  {appointment?.status || ""}
                </p>
                <p>
                  <strong className="px-2">Prescription Status:</strong>
                  {appointment?.isPrescription || ""}
                </p>
                <p>
                  <strong className="px-2">Doctor Name:</strong>
                  {appointment?.doctor.name || ""}
                </p>
                <p>
                  <strong className="px-2">Doctor Specialization:</strong>
                  {appointment?.doctor.specialization || ""}
                </p>
                <p>
                  <strong className="px-2">Doctor Mobile:</strong>
                  {appointment?.doctor.mobile || ""}
                </p>

              </div>
            </div>
          </div>
          {prescription && prescription.map((prescriptionItem, index) => (
            <>
              <div>
                <h3>Prescription Image</h3>
                <a href={`https://api.fever99.com/${prescriptionItem.prescriptionImage}`} target="_blank" rel="noreferrer">
                  <img src={`https://api.fever99.com/${prescriptionItem.prescriptionImage}`} height={"100px"} alt="Prescription" />
                </a>
              </div>
            </>
          ))}
          <h3>Presciption Detail</h3>
          {prescription &&
            prescription.map((prescriptionItem, index) => (
              <div className="doctor-details">
                <div key={index} className="row">
                  <div className="col-md-6">
                    <p>
                      <strong className="px-2">Symptoms:</strong> {prescriptionItem.symptoms}
                    </p>
                    <p>
                      <strong className="px-2">Diagnosis:</strong> {prescriptionItem.diagnosis}
                    </p>
                    <p>
                      <strong className="px-2">Investigation:</strong>{" "}
                      {prescriptionItem.investigation}
                    </p>
                    <p>
                      <strong className="px-2">Past History:</strong>{" "}
                      {prescriptionItem.pastHistory}
                    </p>
                    <p>
                      <strong className="px-2">Surgical History:</strong>{" "}
                      {prescriptionItem.surgicalHistory}
                    </p>
                    <p>
                      <strong className="px-2">Personal History:</strong>{" "}
                      {prescriptionItem.pastHistory}
                    </p>
                    <p>
                      <strong className="px-2">Drug Allergy:</strong>{" "}
                      {prescriptionItem.pastHistory}
                    </p>
                    <p>
                      <strong className="px-2">Notes:</strong> {prescriptionItem.pastHistory}
                    </p>
                    <p>
                      <strong className="px-2">Investigation:</strong>{" "}
                      {prescriptionItem.pastHistory}
                    </p>

                  </div>
                </div>
                {prescriptionItem.medicine.length > 0 && (
                  <div className="medicine-table-container container-fluid">
                    <h4>Medicine Details</h4>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Time</th>
                          <th>Frequency</th>
                          <th>Duration</th>
                          <th>Note</th>
                          <th>Route of Administration</th>
                          <th>Dose Form</th>
                          <th>Duration Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prescriptionItem.medicine.map((medicine, medIndex) => (
                          <tr key={medIndex}>
                            <td>{medicine.name}</td>
                            <td>{medicine.time}</td>
                            <td>{medicine.frequency}</td>
                            <td>{medicine.duration}</td>
                            <td>{medicine.note}</td>
                            <td>{medicine.roa}</td>
                            <td>{medicine.dose_form}</td>
                            <td>{medicine.duration_count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-danger" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AppointmentModal;

import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({  pageNo = 0, size = 10 }) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: false,
      formatter: (cell, row) => {
        return moment(cell).format("DD/MM/YYYY h:mm:ss a");
      },
    },
    {
      dataField: "notificationFor",
      text: "Notification For",
      formatter: (cell, row, rowIndex) => {
        return cell
      },
    },
    {
      dataField: "notificationTitle",
      text: "Notification Title",
      formatter: (cell, row, rowIndex) => {
        return cell
      },
    },
    {
        dataField: "notificationDescription",
        text: "Notification Description",
        formatter: (cell, row, rowIndex) => {
          return cell
        },
      },
    
  ];
};

export default TableColumn;

import React, { useEffect, useState } from "react";
import { storage } from "../../dependencies/store/storage";
import { ROLES } from "../../constants/role";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import TableColumn from "./dependiencies/TableColumn";
import Support from "./AddUpdate/Support";
import { createComplaint, getItemList } from './dependiencies/action';

function Complaint(props) {
  const { ItemList, ItemTotalPage } = useSelector(
    (state) => state.complaint
  );
  const [query,setQuery]=useState({
    page:1,
    size:10
  })
  const [showAddUpdate, setShowAddUpdate] = useState(false);
  const role = storage.getUserRole();

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getItemList(query));
  }, [dispatch,query]);


  const handleAdd = () => {
    dispatch(createComplaint()).then(res => {
      console.log(res)
      if (res.status) {
        setShowAddUpdate(false);
      }
    })
    setShowAddUpdate(true);
  };
  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };
  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col-sm-4 col-3">
              {role === ROLES.PATIENT ? (
                <h4 className="page-title">Tickets</h4>
              ) : (
                <h4 className="page-title">Rasie an issue</h4>
              )}
            </div>
            <div
              className="col-sm-6 col-6 text-right m-b-20"
              style={{ textAlign: "end" }}
            >
            </div>
          </div>
          {
            (role === ROLES.FRANCHISE || role === ROLES.DOCTOR) &&
            <>
              <div
                className=" text-right m-b-20"
                style={{ textAlign: "end" }}>
                <a
                  href="#0"
                  className="btn btn-sm btn-success"
                  onClick={() => setShowAddUpdate(true)}>
                  Create Issue
                </a>
              </div>
            </>
          }
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        // pageNo: query.page - 1,
                        // size: query.size,
                        // handleUpdate,
                        // handleStatus
                      })}
                      dataTable={ItemList}
                      totalRecord={ItemTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                    // sizePerPage={query.size}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Support
        show={showAddUpdate}
        handleClose={() => setShowAddUpdate()}
        handleAdd={() => handleAdd()}
      />
    </>
  );
}

export default Complaint;

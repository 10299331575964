import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import Api from '../../dependencies/utils/Api';
import { FRANCHISE_WALLET_DETAIL } from '../../constants/ApplicationUrl';

const FranchiseWalletModal = ({ show, handleClose }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Function to fetch transactions with pagination
  const fetchTransactions = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const response = await Api.GET(`${FRANCHISE_WALLET_DETAIL}?page=${pageNumber}`);

      setTransactions(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch transactions when the modal is shown
  useEffect(() => {
    if (show) {
      fetchTransactions(page);
    }
  }, [show, page]);

  // Page change handler
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // Function to handle modal close and reset the page to 1
  const handleModalClose = () => {
    setPage(1); // Reset page to 1 when modal is closed
    handleClose(); // Close the modal
  };

  return (
    <>
      <Modal size="xl" show={show} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Franchise Wallet Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <br />
            <table className="table table-responsive table-bordered">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Wallet Balance</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5" className="text-center">Loading...</td>
                  </tr>
                ) : transactions.length > 0 ? (
                  transactions.map((transaction, index) => (
                    <tr key={transaction.id}>
                      <td>{(page - 1) * 10 + index + 1}</td>
                      <td>{transaction.userDetails.name}</td>
                      <td>{transaction.userDetails.email}</td>
                      <td>{transaction.userDetails.mobile}</td>
                      <td>{transaction.balance}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">No transactions found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="pagination justify-content-center mt-4">
            <button
              className="btn btn-primary mr-2"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              Previous
            </button>
            <span className="px-3 mt-1">
              Page {page} of {totalPages}
            </span>
            <button
              className="btn btn-primary ml-2"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-danger" onClick={handleModalClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FranchiseWalletModal;

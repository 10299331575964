import { E_ELINIC_REQUEST } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"

export const CliniCRequestService = {
    // getItemList(params) {
    //     return Api.GET(`${E_ELINIC_REQUEST}?page=${params.page}&size=${params.size}`).then(response => {
    //         const { data: {message, status, data, totalRecord} = {}} = response    
    //         if(status) {
    //             return {status, message, data, totalRecord}
    //         }else {
    //             return {message, status}
    //         }
    //     })
    // },


    getItemList(params) {
        return Api.GET(`${E_ELINIC_REQUEST}?page=${params.page}&size=${params.size}`)
            .then(response => {
                // Check if the response is undefined
                if (!response) {
                    return { status: false, message: 'Error: Undefined response' };
                }

                // Destructure the response and provide default values if keys are missing or undefined
                const { data: { message, status, data, totalRecord } = {} } = response;
                // Check if status is true
                if (status) {
                    return { status, message, data, totalRecord };
                } else {
                    return { message, status };
                }
            })
            .catch(error => {
                // Handle any errors that occur during the request
                console.error('Error in getItemList:', error);
                return { message: 'Error: Request failed', status: false };
            });
    },


}
import {  SET_REFERAL_USER, isLoading,SET_REFERAL_USER_TOTAL_PAGE,SET_REFERAL_TOTAL_INCOME,SET_REFERAL_TRANSACTIONS,SET_REFERAL_TRANSACTIONS_TOTAL_PAGE } from "../../../constants/actionConstants";
import { ReferalService } from "./service";

export const getItemList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await ReferalService.getItemList(params);
    console.log("getItemListRefer",getItemList)
    dispatch({
        type: SET_REFERAL_USER,
        payload: getItemList.data,
    });
    dispatch({
        type: SET_REFERAL_USER_TOTAL_PAGE,
        payload: getItemList.totalPagesReferredUsers,
    }); dispatch({
        type: SET_REFERAL_TOTAL_INCOME,
        payload: getItemList.totalIncome,
    });
    dispatch(isLoading(false));
};

export const getReferalTransactionList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await ReferalService.getReferalTransactionList(params);
    console.log(getItemList,"getItemList")
    dispatch({
        type: SET_REFERAL_TRANSACTIONS,
        payload: getItemList.data,
    });
    dispatch({
        type: SET_REFERAL_TRANSACTIONS_TOTAL_PAGE,
        payload: getItemList.totalPages,
    }); 
    dispatch({
        type: SET_REFERAL_TOTAL_INCOME,
        payload: getItemList.totalIncome,
    });
    dispatch(isLoading(false));
};
import { SET_WITHDRAW_LIST, SET_WITHDRAW_LIST_TOTAL_PAGE, isLoading } from "../../../constants/actionConstants";
import { TeamService } from "./service";
import { toast } from "react-toastify";

export const getWithdrawList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getWithdrawList = await TeamService.getWithdrawList(params);
    dispatch({
        type: SET_WITHDRAW_LIST,
        payload: getWithdrawList.withdrawals,
    });
    dispatch({
        type: SET_WITHDRAW_LIST_TOTAL_PAGE,
        payload: getWithdrawList.totalWithdrawals,
    });

    dispatch(isLoading(false));
};



export const withdraw = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let list = await TeamService.withdraw(params);
    const { status, message } = list
    if (status) {
        toast.success(message)
    } else {
        toast.error(message)
    }
    dispatch(isLoading(false));
    return list
};

export const deletewithdraw = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let save = await TeamService.deletewithdraw(params);
    dispatch(isLoading(false));
    if (save.status) {
        toast.success(save.message)
    }
    else {
        toast.error(save.message)
    }
    return save
};

export const updatewithdraw = (id, params) => async (dispatch) => {
    dispatch(isLoading(true));
    let save = await TeamService.updatewithdraw(id, params);
    dispatch(isLoading(false));
    if (save.status) {
        toast.success(save.message)
    }
    else {
        toast.error(save.message)
    }
    return save
};
import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";
import Dropdown from "react-bootstrap/Dropdown";
import { updateComplent } from "./action";

const TableColumn = ({
  handleAnswer,
  pageNo = 0,
  size = 10,
  handleStatus,
}) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
      dataField: "customerName",
      text: "Customer Name",
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "customerEmail",
      text: "Email",
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "customerMobile",
      text: "Mobile",
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "details",
      text: "Description",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "resolution",
      text: "Resosution",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row, rowIndex) => {
        if (cell === "open") {
          return <span className="text-danger">{cell}</span>;
        } else {
          return <span className="text-success">{cell}</span>;
        }
      },
    },
    {
      dataField: "_id",
      text: "Action",
      hidden: role === ROLES.PATIENT,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() => handleAnswer(row)}
              >
                Reply
              </button>
              <Dropdown alignRight>
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  className="custom-dropdown-form-table"
                >
                  <button className="btn btn-success">update</button>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                 
                    <Dropdown.Item
                      onClick={() => handleStatus(cell, "Followup")}
                    >
                      FollowUp
                    </Dropdown.Item>
                  
                    <Dropdown.Item
                      onClick={() => handleStatus(cell, "Resolved")}
                    >
                      Resolved
                    </Dropdown.Item>
                 
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        );
      },
    },
  ];
};

export default TableColumn;

import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({ handleEdit, HandlejoinMetting, pageNo = 0, size = 10 }) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
        dataField: "createdAt",
        text: "date",
        sort: false,
        formatter: (cell, row) => {
          return moment(cell).format("DD/MM/YYYY h:mm:ss a");
        },
      },
      {
        dataField: "reffererId.name",
        text: "Refferer Name",
        sort: false,
        formatter: (cell, row) => {
          return cell;
        },
      },
      {
        dataField: "reffererId.email",
        text: "Refferer Email",
        sort: false,
        formatter: (cell, row) => {
          return cell;
        },
      },
    {
      dataField: "usedRefferId.name",
      text: "Refferd Name",
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "usedRefferId.email",
      text: "Reffered Email",
      formatter: (cell, row, rowIndex) => {
        return cell
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell, row, rowIndex) => {
        return cell
      },
    },
    
  ];
};

export default TableColumn;

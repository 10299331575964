import React, { useCallback, useEffect, useState } from "react";
import logo from "../../../assets/img/Logo_Hospital.png";
import { useSelector, useDispatch } from "react-redux";
import { doLogin } from "../dependencies/action";
import { useNavigate, Link } from "react-router-dom";
import Loginpopup from "../Loginpopup/Loginpopup";
import "./style.css";
import { FaStar } from "react-icons/fa6";


function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [error, setError] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useEffect(() => { }, []);

  const handleInput = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const loginResponse = await dispatch(doLogin(form));
      console.log('login page so we are getting login details here', loginResponse);
      if (loginResponse.status) {
        let rediret = sessionStorage.getItem("redirect_url");
        if (rediret) {
          sessionStorage.removeItem("redirect_url");
          navigate(rediret);
        } else {
          navigate("/dashboard");
        }
      } else if (loginResponse.error == "User already login in other device") {

        setIsPopupOpen(!isPopupOpen); // Open the popup if login fails
        // alert(isPopupOpen)
        setError(loginResponse.error);
      }
    } catch (error) {
      // setIsPopupOpen(true)
      // console.log(error);
    }
  }, [dispatch, form, isPopupOpen, navigate]);

  const closePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="">
        <div className="bg order-md-2" style={{ backgroundImage: `url("/image/bg-image.avif")` }}>
          <div className="contents order-2 order-md-1">
            <div className="container py-5">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-4 form-signin">
                  <form onSubmit={(e) => handleSubmit(e)} className="bg-white shadow-sm px-5 py-3">
                    <div className="account-logo">
                      <a href="#0">
                        <img src={logo} alt="Preadmin" />
                      </a>
                    </div>
                    <div className="mb-4">
                      <h3 className="text-center">Sign In</h3>
                    </div>
                    <div className="form-group">
                      <label className="py-2">Mobile or Email <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                      <input
                        type="text"
                        placeholder="Mobile or Email"
                        className="form-control p-2 bg-light"
                        name="email"
                        onChange={(e) => handleInput(e.target.name, e.target.value)}
                      />
                      {error ? (
                        <span className="text-danger">{error}</span>
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="py-2">Password <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                      <input
                        type="password"
                        placeholder="Password"
                        className="form-control p-2 bg-light"
                        name="password"
                        onChange={(e) => handleInput(e.target.name, e.target.value)}
                      />
                    </div>
                    <div className="form-group text-right">
                      <Link to="/forgot-password" className="fs-">Forgot your password?</Link>
                    </div>
                    <div>
                      <div className="form-group text-center py-3">
                        <button onClick={handleSubmit} style={{ fontSize: "17px" }} className="btn btn-primary account-btn">
                          Login
                        </button>
                        {isPopupOpen && <Loginpopup onClose={closePopup} form={form} />}
                      </div>
                    </div>
                    <div className="text-center register-link fs-6" style={{ fontSize: "17px" }}>
                      Don’t have an account? <Link to="/register" className="text-primary">Register Now</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { passwordChange, updateProfile, updateDoctor } from "./dependiencies/action";
import { getDocumentLink } from "../../dependencies/utils/helper";
import { isEmpty } from "lodash";
import { storage } from "../../dependencies/store/storage";
import { ROLES } from "../../constants/role";
import { Link } from "react-router-dom";
import AddExperince from "./AddExperince/addExperince";
import AddEducation from "./AddEducation/AddEducation";
import AddAbout from "./AddAbout/addAbout";
import Api from "../../dependencies/utils/Api";
import { DOCTOR_DETAIL, UPDATE_DOCTOR_NEW } from "../../constants/ApplicationUrl";



function EditProfile() {
  const { user } = useSelector((state) => state.login);
  console.log(user, "______________user")
  // const [form, setForm] = useState({});
  const [form, setForm] = useState({ extraDetail: { experience: [], education: [] } });
  const dispatch = useDispatch();
  const [showAddUpdate, setShowAddUpdate] = useState(false);
  const [showEducation, setshowEducation] = useState(false);
  const [showAbout, setshowAbout] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const [error, setError] = useState({});
  const role = storage.getUserRole();
  // useEffect(() => {
  //   setForm(user);
  // }, [user]);
  console.log(form, "form-----------")
  const handleInputChange = (name, value) => {
    console.log("name------", value);

    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let forFata = new FormData();
    let newForm = { ...form };
    delete newForm["pinCode"];
    Object.entries(newForm).map((entry) => {
      const [key, value] = entry;
      forFata.append(key, value);
    });
    dispatch(updateProfile(forFata));
  };

  const validator = () => {
    let errors = {};
    if (!form.oldPassword) {
      errors = { ...errors, oldPassword: "This field is required!" };
    }
    if (!form.newPassword) {
      errors = { ...errors, newPassword: "This field is required!" };
    }
    if (!form.confirmPassword) {
      errors = { ...errors, confirmPassword: "This field is required!" };
    } else if (form.newPassword !== form.confirmPassword) {
      errors = {
        ...errors,
        confirmPassword: "New password and confirm password is not same!",
      };
    }

    setError(errors);

    return errors;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      handleInputChange("image", file);
      const reader = new FileReader();

      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  // git api
  const gettingUserData = async () => {
    setForm(user);
    const doctorId = user._id
    console.log("ghjkl", user._id);
    fetch(`${DOCTOR_DETAIL}/${doctorId}`)
      .then(response => {
        console.log(response, "response>>>>")
        if (!response.ok) {

          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if(data.data.extraDetail){
          setForm(data.data.extraDetail);
        }else{
          setForm({})
        }
        // setForm({ ...form, [name]: value });
        console.log("Fetched Doctor Details:", data.data.extraDetail);
      })
      .catch(error => {
        console.error("Error fetching doctor details:", error);
      });
  }

  useEffect(() => {
    gettingUserData();
  }, []);


  //  delete Api
  const handleDelete = (index) => {
    console.log('Deleting education detail at index:', index);

    // Assuming the education key is already present in the body
    const updatedEducation = form.education.filter((_, eduIndex) => eduIndex !== index);

    Api.PUT(
      `${UPDATE_DOCTOR_NEW}/${index}`,

      ({ 'education': 'education' })
    )
      .then(response => {
        console.log("----====", response);
        gettingUserData();
        return response;
      })
      .then(data => {
        console.log('Education detail deleted successfully:', data);
      })
      .catch(error => {
        console.error('Error deleting education detail:', error);
      });
  }

  const handleDeleteExperince = (index) => {
    console.log('Deleting education detail at index:', index);

    // Assuming the education key is already present in the body
    const updatedEducation = form.education.filter((_, eduIndex) => eduIndex !== index);

    Api.PUT(
      `${UPDATE_DOCTOR_NEW}/${index}`,

      ({ 'experience': 'experience' })
    )
      .then(response => {
        gettingUserData();
        return response;
      })
      .then(data => {
        console.log('Education detail deleted successfully:', data);
        alert('Experience detail deleted successfully');
      });
  }

  const handleChangePassword = (e) => {
    e.preventDefault();

    let vallidate = validator();

    if (!isEmpty(vallidate)) {
      return false;
    }

    dispatch(
      passwordChange({
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        confirmPassword: form.confirmPassword,
      })
    );
  };

  const handleSave = () => {
    debugger
    dispatch(updateDoctor()).then(res => {
      console.log(res)
      if (res.status) {
        setShowAddUpdate(false);
      }
    })
    setShowAddUpdate(true);
  };

  return (
   <section>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-sm-12">
              <h4 className="page-title">Edit Profile</h4>
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="card-box">
              <h3 className="card-title">Basic Informations</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-img-wrap">
                    {/* {form.image ? (
                      <img src="https://www.vhv.rs/dpng/d/15-155087_dummy-image-of-user-hd-png-download.png" className="img-fluid rounded-top w-100" alt="Default" />
                    ) : (
                      <img src={getDocumentLink(user.image)} className="img-fluid w-100" alt="User" />
                    )} */}
                    <img 
  src={
    form && form.image === "user.png" 
      ? "https://www.vhv.rs/dpng/d/15-155087_dummy-image-of-user-hd-png-download.png" 
      : getDocumentLink(user.image)
  } 
  className="img-fluid rounded-top w-100" 
  alt="User" 
/>

                    <div className="fileupload btn">
                      <span className="btn-text">Edit</span>
                      <input
                        className="upload"
                        type="file"
                        onChange={(e) => handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <div className="profile-basic">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="focus-label">Name</label>
                        <div className="form-group form-focus">
                          <input
                            type="text"
                            name="name"
                            className="form-control floating"
                            value={form.name}
                            readOnly
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="focus-label">Phone Number</label>
                        <div className="form-group form-focus">
                          <input
                            type="number"
                            className="form-control floating"
                            value={form.mobile}
                            name="mobile"
                            readOnly
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                      {role === ROLES.DOCTOR && (
                        <div className="col-md-6">
                          <label className="focus-label">
                            Appointment per charge Rs.
                          </label>
                          <div className="form-group form-focus">
                            <input
                              type="number"
                              name="serviceCharge"
                              readOnly
                              className="form-control floating"
                              value={form.serviceCharge}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <label className="focus-label">Email</label>
                        <div className="form-group form-focus">
                          <input
                            type="text"
                            className="form-control floating"
                            value={form.email}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="focus-label">Gendar</label>
                        <div className="form-group form-focus select-focus">
                          <select
                            className="select form-control floating"
                            name="gender"
                            readOnly
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                          >
                            <option
                              value="Male"
                              selected={form.gender === "Male" ? true : false}
                            >
                              Male
                            </option>
                            {/* <option
                              value="Female"
                              selected={form.gender === "Female" ? true : false}
                            >
                              Female
                            </option>
                            <option
                              value="Other"
                              selected={form.gender === "Other" ? true : false}
                            >
                              Other
                            </option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-box">
              <h3 className="card-title">Contact Informations</h3>
              <div className="row">
                <label className="focus-label">Address</label>
                <div className="col-md-12 mt-2">
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      value={form.address}
                      name="address"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="focus-label">State</label>
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      value={form.state}
                      name="state"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="focus-label">Country</label>
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      value="India"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="focus-label">Pin Code</label>
                  <div className="form-group form-focus">
                    <input
                      type="number"
                      className="form-control floating"
                      value={form.pinCode}
                      name="pinCode"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                {role === ROLES.DOCTOR && (
                  <>
                    <div className="col-md-6 mt-3">
                      <label className="focus-label">Specialization</label>
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          readOnly
                          className="form-control floating"
                          value={form.specialization}
                          name="specialization"
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3 col-md-6">
                      <label>Mau Document</label>
                      <input
                        name="mou"
                        className="form-control"
                        type="file"
                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                      />
                      {form.mou && form.mou instanceof File ? (
                        <img src={URL.createObjectURL(form.mou)} alt="Preview" style={{ maxWidth: "100px", marginTop: "5px" }} />
                      ) : (
                        <img
                          className="inline-block"
                          src={form.mou ? getDocumentLink(form.mou) : ""}
                          alt="user"
                          style={{ maxWidth: "100px", pointerEvents: "none" }}
                        />
                      )}
                    </div>
                  </>
                )}
                {role === ROLES.FRANCHISE && (
                  <>
                    <div className="form-group mt-2 col-md-6 mt-3">
                      <label>Pan Card</label>
                      <input
                        name="pan"
                        className="form-control"
                        type="file"
                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                      />
                      {form.pan && form.pan instanceof File ? (
                        <img src={URL.createObjectURL(form.pan)} alt="Preview" style={{ maxWidth: "100px", marginTop: "5px" }} />
                      ) : (
                        <img
                          className="inline-block"
                          src={form.panNumber ? getDocumentLink(form.panNumber) : ""}
                          alt="user"
                          style={{ maxWidth: "100px", pointerEvents: "none" }}
                        />
                      )}
                    </div>
                    <div className="form-group mt-2 col-md-6 mt-3">
                      <label>Aadhar Card</label>
                      <input
                        name="aadhar_card"
                        className="form-control"
                        type="file"
                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                      />
                      {form.aadhar_card && form.aadhar_card instanceof File ? (
                        <img src={URL.createObjectURL(form.aadhar_card)} alt="Preview" style={{ maxWidth: "100px", marginTop: "5px" }} />
                      ) : (
                        <img
                          className="inline-block"
                          src={form.aadharNumber? getDocumentLink(form.aadharNumber) : ""}
                          alt="user"
                          style={{ maxWidth: "100px", pointerEvents: "none" }}
                        />
                      )}
                    </div>
                    <div className="form-group mt-2 col-md-6 mt-3">
                      <label>License</label>
                      <input
                        name="licence"
                        className="form-control"
                        type="file"
                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                      />
                      {form.licence && form.licence instanceof File ? (
                        <img src={URL.createObjectURL(form.licence)} alt="Preview" style={{ maxWidth: "100px", marginTop: "5px" }} />
                      ) : (
                        <img
                          className="inline-block"
                          src={form.licence ? getDocumentLink(form.licence) : ""}
                          alt="user"
                          style={{ maxWidth: "100px", pointerEvents: "none" }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {
              (role === ROLES.DOCTOR) &&
              <>
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="col-sm-4 col-3">
                    <h4 className="page-title">Experince</h4>
                  </div>

                  <div
                    className="col-sm-8 col-9 text-right m-b-20"
                    style={{ textAlign: "end" }}>
                    <a
                      href="#0"
                      className="btn btn-sm btn-success"
                      onClick={() => setShowAddUpdate(true)}>
                      Add Experince
                    </a>
                  </div>
                </div>
                <h4 className="mb-1">Experince</h4>
                <div className="row p-2">
                  {form.experience && form.experience.map((experience, index) => (
                    <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-2">
                      <div className="bg-white p-2 px-2">
                        <div className="d-flex justify-content-between">
                          <span className="fw-bold">{experience.JobTitle}</span>
                          <span onClick={() => handleDeleteExperince(index)}><i class="fas fa-trash text-danger"></i></span>
                        </div>
                        <div>
                          <span className="">{experience.companyName}</span>
                        </div>
                        <div><span className="">{experience.cityState}</span></div>
                        <div><span>{experience.startMonth} {experience.startYear} - {experience.endMonth} {experience.endYear}</span></div>
                        <div><span className="">{experience.JobDescription}</span></div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="col-sm-4 col-3">
                    <h4 className="page-title">Education</h4>
                  </div>

                  <div
                    className="col-sm-8 col-9 text-right m-b-20"
                    style={{ textAlign: "end" }}>
                    <a
                      href="#0"
                      className="btn btn-sm btn-success"
                      onClick={() => setshowEducation(true)}>
                      Add Education
                    </a>
                  </div>
                </div>
                <h4 className="mb-1">Education</h4>
                <div className="row p-2">
                  {form.education && form.education.map((education, index) => (
                    <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-2">
                      <div className="bg-white p-2 px-2">
                        <div className="d-flex justify-content-between">
                          <span className="fw-bold">{education.study}</span>
                          <span onClick={() => handleDelete(index)}><i class="fas fa-trash text-danger"></i></span>
                        </div>
                        <div>
                          <span className="">{education.country}</span>
                        </div>
                        <div><span className="">{education.collegeName}</span></div>
                        <div><span className="">{education.cityState}</span></div>
                        <div><span>{education.startMonth} {education.startYear} - {education.endMonth} {education.endYear}</span></div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="col-sm-4 col-3">
                    <h4 className="page-title">About Me</h4>
                  </div>

                  <div
                    className="col-sm-8 col-9 text-right m-b-20"
                    style={{ textAlign: "end" }}>
                    <a
                      href="#0"
                      className="btn btn-sm btn-success"
                      onClick={() => setshowAbout(true)}>
                      Add About
                    </a>
                  </div>
                </div>
              </>
            }
            <div className="card-box">
              <h3 className="card-title my-2">Change Password</h3>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="focus-label">Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={form.oldPassword}
                      name="oldPassword"
                      placeholder="Old Password"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                    {error && error.oldPassword && (
                      <span className="text-danger d-inline-block w-100">
                        {error.oldPassword}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="focus-label">New Password</label>
                    <input
                      type="password"
                      className="form-control floating"
                      value={form.newPassword}
                      name="newPassword"
                      placeholder="New Password"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                    {error && error.newPassword && (
                      <span className="text-danger d-inline-block w-100">
                        {error.newPassword}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="focus-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control floating"
                      value={form.confirmPassword}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                    {error && error.confirmPassword && (
                      <span className="text-danger d-inline-block w-100">
                        {error.confirmPassword}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="add-more d-flex justify-content-end my-3">
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={(e) => handleChangePassword(e)}
                >
                  Change Password
                </Link>
              </div>
            </div>
            <div className="text-center m-t-20">
              <button
                className="btn btn-primary submit-btn"
                type="button"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </button>
            </div>
          </form>

        </div>
      </div>
      <AddExperince
        show={showAddUpdate}
        handleClose={() => setShowAddUpdate()}
        handleSave={() => handleSave()}
      />
      <AddEducation
        show={showEducation}
        handleClose={() => setshowEducation()}
        handleSave={() => handleSave()}
      />
      <AddAbout
        show={showAbout}
        handleClose={() => setshowAbout()}
        handleSave={() => handleSave()}
      />
    </section>
  )
}

export default EditProfile;

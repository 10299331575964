import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({
  HandleEdit,
  pageNo = 0,
  size = 10,
  openModal,
  openTransactionModal,
  openWalletModal,
  openReferModal
}) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return (pageNo - 1) * size + (rowIndex + 1);
      },
    },
    {
      dataField: "createdAt",
      text: "Registration date",
      sort: false,
      formatter: (cell, row) => {
        if (cell) {
          const createdAtDate = new Date(cell);
          const day = String(createdAtDate.getDate()).padStart(2, "0");
          const month = String(createdAtDate.getMonth() + 1).padStart(2, "0");
          const year = String(createdAtDate.getFullYear());
          const formattedDate = `${day}-${month}-${year}`;
          return formattedDate;
        } else {
          return "";
        }
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      formatter: (cell, row) => {
        return (
          <a href="#0" onClick={() => openModal(row)}>
            {cell}
          </a>
        );
      },
    },
    {
      dataField: "wallet",
      text: "Wallet",
      sort: false,
      formatter: (cell, row) => {
        return <a href="#0" onClick={() => openWalletModal(row)}>click Here</a>;
      },
    },
    {
      dataField: "transaction",
      text: "Transation",
      sort: false,
      formatter: (cell, row) => {
        return (
          <a href="#0" onClick={() => openTransactionModal(row)}>Click Here
          </a>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "gender",
      text: "Gender",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "refrelCode",
      text: "Refral Code",
      formatter: (cell, row, rowIndex) => {
        return (
          <a href="#0" onClick={() => openReferModal(row)}>{cell}
          </a>
          
        );
      },
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <button className="btn btn-info" onClick={() => HandleEdit(row)}>
              Edit
            </button>
          </>
        );
      },
    },
  ];
};

export default TableColumn;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorList } from "../dependiencies/action";
import "react-calendar/dist/Calendar.css";
import AddModal from "./AddModal";
import { getDocumentLink } from "../../../dependencies/utils/helper";
import { storage } from "../../../dependencies/store/storage";
import "./style.scss";
import { ROLES } from "../../../constants/role";
import { getStateCity } from "../../Dashboard/dependencies/action";
import DynamicPagination from "./DynamicPagination";
import { MultiSelect } from "react-multi-select-component";

function AppointmentAddUpdate(props) {
  const location = storage.getLocation() ?? "";
  const role = storage.getUserRole();
  const [query, setQuery] = useState({
    page: 1,
    limit: 6,
    name: "",
    specialization: "",
    state: "",
    city: "",
    pricesort: "DESC",
  });
  const [showModal, setShowModal] = useState(false);
  const [doctoe, setDoctor] = useState(null);
  // const [showCalendar, setShowCalendar] = useState({ status: false, id: "" });
  // const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const [name, setString] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [type, setType] = useState("name");
  const [mode, setMode] = useState("video");
  const [selected, setSelected] = useState([]);
  const [state, setState] = useState([]);
  const { stateList } = useSelector((state) => state.stateCity);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  console.log(selectedCity, "cityhai ");
  const sortedStateList = stateList.slice().sort((a, b) => {
    const stateA = a.state.toUpperCase();
    const stateB = b.state.toUpperCase();

    if (stateA < stateB) {
      return -1;
    }
    if (stateA > stateB) {
      return 1;
    }
    return 0;
  });
  console.log("sortedStateList____", sortedStateList[0]?.state);
  const [city, setCity] = useState([]);
  const { ItemList, ItemTotalPage, Spacility } = useSelector(
    (state) => state.doctors
  );
  console.log("this is city", city);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleDoctorSearchChange = (value) => {
    setQuery({ ...query, query: value });
  };

  useEffect(() => {
    dispatch(getDoctorList(query));
    dispatch(getStateCity());
  }, [dispatch, query]);

  const handleBookApointment = (row, mode) => {
    setMode(mode);
    setShowModal(true);
    setDoctor(row);
    //console.log('row data is here book time slot',row)
  };
  const handletypeChange = (e) => {
    if (e.target.value) {
      setType(e.target.value);
    }
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    const selectedCities = value.map((option) => option.value); // Extracting value property from selected option
    setQuery({ ...query, city: selectedCities });
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     searchDoctors(e);
  //   }
  // };

  const handlePageClick = (page) => {
    setQuery({ ...query, page: page });

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const options = Spacility.map((item, index) => ({ value: item, label: item }));

  // const searchDoctors = (e) => {
  //   e.preventDefault();
  //   const selectedSpecializations = selected.map((option) => option.value);
  //   const selectedState = state.map((option) => option.value);
  //   // Convert selected cities to an array of strings
  //   const selectedCityValues = cities.map((city) => city.value);
  //   const selectedCities = cities.filter((city) =>
  //     selectedCityValues.includes(city.value)
  //   );
  //   setQuery({
  //     ...query,
  //     name,
  //     specialization: selectedSpecializations,
  //     state: selectedState,
  //     city: selectedCities,
  //   });
  //   console.log(query, "tyuio");
  // };

  const cityOptions = city.map((city) => ({ label: city, value: city }));
  const handleNameChange = (e) => {
    const newName = e.target.value;
    setString(newName);
    setQuery({ ...query, name: newName });
  };

  const options = [
    { label: "General Physician", value: "General Physician" },
    { label: "Consultant Physician", value: "Consultant Physician" },
    { label: "General Surgeon", value: "General Surgeon" },
    { label: "OBS Gynaecologist", value: "OBS Gynaecologist" },
    { label: "Paediatrician", value: "Paediatrician" },
    { label: "Orthopaedician", value: "Orthopaedician" },
    { label: "Dermatologist", value: "Dermatologist" },
    { label: "Pulmonologist", value: "Pulmonologist" },
    { label: "Psychiatrist", value: "Psychiatrist" },
    { label: "ENT Surgeon", value: "ENT Surgeon" },
    { label: "Ophthalmologist", value: "Ophthalmologist" },
    { label: "Diabetologist", value: "Diabetologist" },
    { label: "Cardiologist", value: "Cardiologist" },
    { label: "Cardiac Surgeon", value: "Cardiac Surgeon" },
    { label: "Nephrologist", value: "Nephrologist" },
    { label: "Urologist", value: "Urologist" },
    { label: "Gastroenterologist", value: "Gastroenterologist" },
    { label: "GI Surgeon", value: "GI Surgeon" },
    { label: "Neurophysician", value: "Neurophysician" },
    { label: "Neurosurgeon", value: "Neurosurgeon" },
    { label: "Neonatologist", value: "Neonatologist" },
    { label: "Endocrinologist", value: "Endocrinologist" },
    { label: "Rheumatologist", value: "Rheumatologist" },
    { label: "Oncologist", value: "Oncologist" },
    { label: "Haematologist", value: "Haematologist" },
    { label: "Onco Surgeon", value: "Onco Surgeon" },
    { label: "Dentist", value: "Dentist" },
    { label: "Physiotherapist", value: "Physiotherapist" },
    { label: "Dietetician", value: "Dietetician" },
    { label: "Clinical Nutritionist", value: "Clinical Nutritionist" },
    { label: "Psychologist", value: "Psychologist" },
    { label: "Homoeopathy Specialist", value: "Homoeopathy Specialist" },
    { label: "Ayurveda Specialist", value: "Ayurveda Specialist" },
    { label: "Unani Specialist", value: "Unani Specialist" },
    { label: "Electrohomeopathy Spcl.", value: "Electrohomeopathy Spcl." },
    { label: "Sexologist", value: "Sexologist" },
    { label: "Others", value: "Others" },
  ];
  const optionsState = sortedStateList.map((item, index) => ({
    label: item.state,
    value: item.state, // You can set the value property to the same as label or to some other value
  }));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const handleStateChange = (e) => {
    let stateName = e.target.value;
    setSelectedState(e.target.value);
    let selectedState = stateList.find((state) => state.state === stateName);
    if (selectedState) {
      let sortedCities = selectedState.city.slice().sort((a, b) => {
        const cityA = a.toUpperCase();
        const cityB = b.toUpperCase();
        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }
        return 0;
      });

      setQuery({
        ...query,
        state: selectedState.state,
      });
      setCity(sortedCities);
    }
  };
  const [selectedStates, setSelectedStates] = useState([]);
  const handleChange = (selected) => {
    setSelectedOptions(selected);
    // Extract selected state names from the selected array
    const selectedStateNames = selected.map((option) => option.value);
    setSelectedStates(selectedStateNames);
    // Find cities for all selected states
    const cities = selectedStateNames.flatMap((stateName) => {
      const selectedState = stateList.find(
        (state) => state.state === stateName
      );
      return selectedState ? selectedState.city : [];
    });
    // Sort and remove duplicates from city list
    const uniqueSortedCities = [...new Set(cities)].sort((a, b) =>
      a.localeCompare(b)
    );
    console.log(uniqueSortedCities, "sort");
    setCity(uniqueSortedCities);
    // Update query
    setQuery({ ...query, state: selectedStateNames });
    console.log("Selected value:", selectedStateNames);
  };
  const handleSpecializationChange = (selectedOptions) => {
    setSelected(selectedOptions);
    const selectedSpecializations = selectedOptions.map(
      (option) => option.value
    );

    setQuery({ ...query, specialization: selectedSpecializations });
  };
  const clearFilters = () => {
    setQuery({
      page: 1,
      limit: 6,
      name: "",
      specialization: "",
      state: "",
      city: "",
      pricesort: "DESC",
    });
    setSelected([])
    setString(""); // Clear the name input field
    setSpecialization(""); // Clear the specialization input field
    setSelectedCity([]); // Clear the selected city
    setSelectedOptions([]); // Clear the selected states
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-sm-6 col-9 m-b-20">
              <h4 className="page-title">Book Appointment</h4>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-3">
                  <input
                    name="name"
                    className="form-control"
                    placeholder="Doctor Name"
                    value={name}
                    // onKeyDown={handleKeyPress}
                    onChange={handleNameChange}
                  />
                </div>
                {/* <div className="col-lg-3">
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={(selectedOptions) => setSelected(selectedOptions)}
                    labelledBy="Select"
                    searchable={true}
                  />
                </div> */}
                <div className="col-lg-3">
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={handleSpecializationChange}
                    labelledBy="Select"
                  />
                </div>
                <div className="col-lg-3">
                  {/* <select
                    name="state"
                    onChange={(e) => handleStateChange(e)}
                    className="form-select"
                    onKeyDown={handleKeyPress}>
                    <option value="">Select State</option>
                    {sortedStateList &&
                      sortedStateList.map((itm, index) => (
                        <option value={itm.state} key={index}>
                          {itm.state}
                        </option>
                      ))}
                  </select> */}

                  <MultiSelect
                    options={optionsState}
                    value={selectedOptions}
                    onChange={handleChange}
                    labelledBy="Select"
                  />
                </div>

                <div className="col-lg-3">
                  <MultiSelect
                    options={cityOptions}
                    value={selectedCity} // Bind selected cities to the input field
                    onChange={handleCityChange}
                    labelledBy="Select"
                  />
                </div>

                <div className="d-flex justify-content-center my-3">
                  {/* <button
                    type="button"
                    className="btn btn-sm btn-info"
                    onClick={(e) => searchDoctors(e)}
                    onKeyDown={handleKeyPress}
                  >
                    Search
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={clearFilters}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="doctor-list">
            {ItemList &&
              ItemList.map((item, index) => (
                <div className="doctor-card" key={index}>
                  <label
                    style={{
                      position: "relative",
                      top: "-28px",
                      right: "-230px",
                      fontWeight: "bolder",
                    }}
                  >
                    <span
                      className={`status ${item.userStatus ?? "offline"}`}
                      style={{
                        position: "absolute",
                        left: "-14px",
                        top: "8px",
                      }}
                    ></span>{" "}
                    {item.userStatus == "online"
                      ? "Available"
                      : "Not Available"}
                  </label>
                  <div className="doctor-img">
                    <a className="avatar" href="#0">
                      <img src={getDocumentLink(item.image)} alt={item.name} />
                    </a>
                  </div>

                  <div className="doctor-details">
                    <h4 className="doctor-name text-ellipsis text-center">
                      <a href="#0">{item.name}</a>
                    </h4>

                    <p className="label">
                      {item.userExtraDetails && item.userExtraDetails.degree}
                    </p>
                    <p className="label">{item.specialization ?? ""}</p>
                    <p>
                      <b>Location: </b> {item.city}, {item.state}
                    </p>
                    <p className="badge bg-success">
                      <b>Experience: </b>
                      {item.userExtraDetails &&
                        item.userExtraDetails.totalExperience}{" "}
                      Years
                    </p>
                    <p style={{ minHeight: "48px" }}>
                      <b>Working: </b>
                      {item.userExtraDetails &&
                        item.userExtraDetails.currentOrganization}
                    </p>

                    {item &&
                      item.languageKnown &&
                      item.languageKnown.lenth &&
                    {
                      /* <p><b>Language: </b> Hindi, English</p> */
                    }}
                  </div>
                  {ROLES.PATIENT === role ? (
                    <>
                      <p className="service-charge">
                        {item.serviceChargepatient === 0 ||
                          item.serviceChargepatient === undefined ? (
                          <span>Free</span>
                        ) : (
                          <span>Rs {item.serviceChargepatient}</span>
                        )}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="service-charge">
                        {item.serviceCharge === 0 ? (
                          <span>Free</span>
                        ) : (
                          <span>Rs {item.serviceCharge}</span>
                        )}
                      </p>
                    </>
                  )}

                  <div className="appointment-buttons">
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      onClick={() => handleBookApointment(item, "Video")}
                    >
                      Book Video Consult
                    </button>
                    {ROLES.PATIENT == role && (
                      <button
                        type="button"
                        className="btn btn-sm btn-warning"
                        onClick={() => handleBookApointment(item, "Offline")}
                      >
                        Book Clinic Visit
                      </button>
                    )}
                  </div>
                </div>
              ))}
            {!ItemList && <h3>No Record Found!</h3>}
          </div>
          <DynamicPagination
            totalRecords={ItemTotalPage}
            recordsPerPage={6}
            currentPage={query.page}
            onPageChange={handlePageClick}
          />
        </div>
      </div>
      <AddModal
        mode={mode}
        showModal={showModal}
        handleClose={(value) => setShowModal(value)}
        data={doctoe}
      />
    </>
  );
}

export default AppointmentAddUpdate;

import { ServiceRequestService } from "./service";
import { SET_COMPLAINT_LIST, SET_COMPLAINT_TOTALRECORD, SET_COMPLAINT_TOTAL_PAGES, isLoading, } from "../../../constants/actionConstants";
import {
    toast
} from "react-toastify";

export const getItemList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await ServiceRequestService.getItemList(params);
    console.log("getItemList----------", getItemList);
    dispatch({
        type: SET_COMPLAINT_LIST,
        payload: getItemList.raiseComplaints,
    });
    dispatch({
        type: SET_COMPLAINT_TOTALRECORD,
        payload: getItemList.totalData,
    });
    dispatch({
        type: SET_COMPLAINT_TOTAL_PAGES,
        payload: getItemList.totalPages,
    });
    dispatch(isLoading(false));
};

export const createComplaint = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let save = await ServiceRequestService.createComplaint(params);
    console.log("hkl;", save);
    const { status, message } = save
    if (status) {
        toast.success(message)

    } else {
        toast.error(message)
    }
    dispatch(isLoading(false));
    return save
}

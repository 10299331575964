import { GET_PROMOTION_NOTIFICATION_LIST,GET_USER_FOR_NOTIFICATION } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"

export const PromotionNotification = {
    getItemList(params) {
        return Api.GET(`${GET_PROMOTION_NOTIFICATION_LIST}?page=${params.page}&fromDate=${params.fromDate}&toDate=${params.toDate}`).then(response => {
            const { data: {message, status, data,totalPages,totalNotifications} = {}} = response    
            if(status) {
                return {message, data,totalPages,totalNotifications}
            }else {

            }
        })
    },
    getUserItemList(params) {
        console.log(params, "params")
        return Api.GET(`${GET_USER_FOR_NOTIFICATION}/${params}`).then(response => {
            const { data: { message, status, data,} = {} } = response
            if (status) {
                return { message, data }
            } else {

            }
        })
    },
    sendNotification(params) {
        return Api.POST(GET_PROMOTION_NOTIFICATION_LIST,params).then(response => {
            const { data: {message, status} = {}} = response    
            if(status) {
                return {message,status}
            }else {
                return {message}
            }
        })
    },

}
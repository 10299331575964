import React, { useEffect, useId, useState } from 'react';
import { Modal } from "react-bootstrap";
import Api from '../../dependencies/utils/Api';
import { useDispatch, useSelector } from "react-redux";
import { getItemList } from "../Referal/dependencies/action";
import moment from 'moment';
import ReferalTrasactionModal from '../Referal/ReferalTransactionModal';
import { useModal } from '../UseModal/UseModal';
const ReferalModal = ({ show, handleClose, user }) => {
    const [query, setQuery] = useState({ userId: '', page: 1, size: 10, fromDate: '', toDate: '' });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { ItemList, ItemTotalPage, TotalIncome } = useSelector((state) => state.referalUser);
    const [showModal, setShowModal] = useState(false);
    const [usedRefferId,setUsedRefferId]=useState(false)
    useEffect(() => {
        if (user && user._id) {
            setQuery(prevQuery => ({
                ...prevQuery,
                userId: user._id // Set userId from user._id
            }));
        }
    }, [user]);

    useEffect(() => {
        if (show && query.userId) {
            setLoading(true);
            dispatch(getItemList(query))
                .finally(() => setLoading(false));
        }
    }, [dispatch, show, query]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= ItemTotalPage) {
            setQuery({ ...query, page: newPage });
        }
    };

    const handleModalClose = () => {
        handleClose();
    };
    const clearFilter = () => {
        setQuery({
            page: 1,
            size: 10,
            fromDate: '',
            toDate: '',
            userId: ''
        })
    }
    const handleNameClick = (transaction) => {
        console.log(transaction,"trans")
        setUsedRefferId(transaction._id)
        setShowModal(true); // Show ReferalTransactionModal
    };

    const handleTransactionModalClose = () => {
        setShowModal(false); // Close the transaction modal
    };

    return (
        <>
            <Modal size="xl" show={show} onHide={handleModalClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Refferal Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row m-b-20">

                        <div className="col-4">
                            <label>From Date</label>
                            <input
                                type="date"
                                name="fromDate"
                                value={query.fromDate}
                                className="form-control"
                                onChange={(e) => setQuery(prevQuery => ({
                                    ...prevQuery,
                                    fromDate: e.target.value,
                                }))}
                            />
                        </div>
                        <div className="col-4">
                            <label>To Date</label>
                            <input
                                type="date"
                                name="toDate"
                                value={query.toDate}
                                min={query.fromDate}
                                className="form-control"
                                onChange={(e) => setQuery(prevQuery => ({
                                    ...prevQuery,
                                    toDate: e.target.value,
                                }))}
                            />
                        </div>
                        <div className="col-4">
                            <button className="btn btn-danger ml-2" onClick={clearFilter}>
                                Clear Filter
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <br />
                        <table className="table table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Gender</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">Loading...</td>
                                    </tr>
                                ) : ItemList.length > 0 ? (
                                    ItemList.map((transaction, index) => (
                                        <tr key={transaction._id}>
                                            <td>{(query.page - 1) * query.size + index + 1}</td>
                                            <td>{moment(transaction.createdAt).format("DD/MM/YYYY h:mm:ss a")}</td>
                                            <td
                                                className="text-primary cursor-pointer"
                                                onClick={() => handleNameClick(transaction)} // Handle click on the name
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {transaction.name}
                                            </td>
                                            <td>{transaction.email}</td>
                                            <td>{transaction.gender}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">No transactions found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination justify-content-center mt-4">
                        <button
                            className="btn btn-primary mr-2"
                            onClick={() => handlePageChange(query.page - 1)}
                            disabled={query.page === 1}
                        >
                            Previous
                        </button>
                        <span className="px-3 mt-1">
                            Page {query.page} of {ItemTotalPage}
                        </span>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => handlePageChange(query.page + 1)}
                            disabled={query.page === ItemTotalPage}
                        >
                            Next
                        </button>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-danger" onClick={handleModalClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            {showModal && (
                <ReferalTrasactionModal
                    show={showModal}
                    handleClose={handleTransactionModalClose}
                    usedRefferId={usedRefferId} // Pass the selected transaction data
                />
            )}
        </>
    );
};

export default ReferalModal;

import React, { useState,useEffect } from "react";
// import DarkLogo from "../../../assets/img/logo-dark.png";
import logo from "../../../assets/img/Logo_Hospital.png";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  doRegisterDoctor,
  doRegisterUser,
  requestRegisterOTP,
} from "../dependencies/action";
import { isEmpty } from "lodash";
import { FaStar } from "react-icons/fa6";
import "./style.css";


function FranchiseRegister() {
  const navigate = useNavigate();
  const { referralCode } = useParams(); // Capture referralCode from the URL
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [form, setForm] = useState({});
  const [otpRequest, setOtpRequest] = useState(false);
  const mobileNumberPattern = /^[0-9]{10}$/;
  const [disableRegisterBtn, setDisableRegisterBtn] = useState(true);
  const [privacyCheck, setPrivacyCheck] = useState(false);

  const handleInput = (name, value) => {
    setForm({ ...form, [name]: value });
  };
  console.log(referralCode,"reffer")

  const validator = () => {
    let errors = {};
    if (!form.name) {
      errors = { ...errors, name: "This field is required!" };
    }
    if (!form.mobile) {
      errors = { ...errors, mobile: "This field is required!" };
    }
    if (!form.email) {
      errors = { ...errors, email: "This field is required!" };
    }
    if (!form.gender) {
      errors = { ...errors, gender: "This field is required!" };
    }

    if (!form.password) {
      errors = { ...errors, password: "This field is required!" };
    } else if (form.password !== form.rePassword) {
      errors = {
        ...errors,
        password: "Confirm Password not matching with Password",
      };
    }

    setError(errors);

    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let vallidate = validator();

    if (!isEmpty(vallidate)) {
      return false;
    }

    const register = dispatch(doRegisterUser(form));

    register.then((res) => {
      if (res.status) {
        navigate("/login");
      }
    });
  };

  const handleRequestOtp = (e) => {
    e.preventDefault();

    if (!form.mobile) {
      setError({ ...error, mobile: "Mobile is not valid!" });
      return false;
    }

    if (!mobileNumberPattern.test(form.mobile)) {
      setError({ ...error, mobile: "Mobile is not valid!" });
      return false;
    }

    dispatch(requestRegisterOTP({ mobile: form.mobile })).then((res) => {
      if (res) {
        setOtpRequest(true);
        setDisableRegisterBtn(false)
      }
    });
  };
  useEffect(() => {
    if (referralCode) {
      setForm({ ...form, usedRefrel: referralCode }); // Set referral code if available
    }
  }, [referralCode]);
  console.log(privacyCheck, disableRegisterBtn);
  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="main-wrapper">
        <div className="bg order-md-2" style={{ backgroundImage: `url("/image/bg-image.avif")` }}>
          <div className="account-box register-wrapp wrap-login100">
            <form onSubmit={(e) => handleSubmit(e)} className="login100-form">
              <div className="account-logo">
                <a href="#0">
                  <img src={logo} alt="Preadmin" />
                </a>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <label>Name <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  />
                  {error && error.name && (
                    <span className="text-danger">{error.name}</span>
                  )}
                </div>
                <div className="form-group col-lg-6">
                  <label>Email Address <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                  <input
                    type="email"
                    placeholder="Email id"
                    className="form-control"
                    name="email"
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  />
                  {error && error.email && (
                    <span className="text-danger">{error.email}</span>
                  )}
                </div>
                <div className="form-group col-lg-6">
                  <label>Mobile Number <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile No."
                    name="mobile"
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  />

                  {error && error.mobile && (
                    <span className="text-danger">{error.mobile}</span>
                  )}
                </div>
                <div className="form-group col-lg-6">
                  {otpRequest ? (
                    <>
                      <label>OTP <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="OTP"
                        name="otp"
                        onChange={(e) =>
                          handleInput(e.target.name, e.target.value)
                        }
                      />
                    </>
                  ) : (
                    <Link
                      onClick={(e) => handleRequestOtp(e)}
                      style={{ marginTop: "22px" }}
                      className="btn btn-warning"
                    >
                      Request OTP
                    </Link>
                  )}
                </div>
                <div className="form-group col-lg-6">
                  <label>Gender <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                  <select
                    name="gender"
                    className="form-control"
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  >
                    <option value="">--Select Gender--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {error && error.gender && (
                    <span className="text-danger">{error.gender}</span>
                  )}
                </div>

                <div className="form-group col-lg-6">
                  <label>Refral Code <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                  <input
                    name="usedRefrel"
                    placeholder="Refral Code"
                    className="form-control"
                    value={form.usedRefrel} 
                    
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  />
                </div>

                <div className="form-group col-lg-6">
                  <label>Password <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  />
                  {error && error.password && (
                    <span className="text-danger">{error.password}</span>
                  )}
                </div>
                <div className="form-group col-lg-6">
                  <label>Confirm Password <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="confirm Password"
                    name="rePassword"
                    onChange={(e) => handleInput(e.target.name, e.target.value)}
                  />
                  {error && error.rePassword && (
                    <span className="text-danger">{error.rePassword}</span>
                  )}
                </div>
                <div className="form-group checkbox col-md-12">
                  <label>
                    <input
                      type="checkbox"
                      name="privacy"
                      onChange={(e) => setPrivacyCheck(e.target.checked)}
                    />{" "}
                    I accept Fever99{" "}
                    <Link
                      to="/privacy"
                      target="_blank"
                      style={{ color: "#009ce7" }}
                    >
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/terms-condition"
                      target="_blank"
                      style={{ color: "#009ce7" }}
                    >
                      Terms & Conditions
                    </Link>
                  </label>
                </div>
                <div className="form-group text-center">
                  <button style={{ background: "#1263AC" }}
                    className={`btn btn-success account-btn ${privacyCheck ? `` : `disabled`} ${disableRegisterBtn ? `disabled` : ``}`}
                    type="submit">
                    Signup
                  </button>
                </div>
                <div className="text-center login-link">
                  Already have an account? <Link to="/login" className="text-primary">Login</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default FranchiseRegister;

import { toast } from "react-toastify";
import { isLoading } from "../../../constants/actionConstants";
import { ProfileService } from "./service";

export const updateAccount = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  let bankdetail = await ProfileService.accountupdate(params);
  dispatch(isLoading(false));
  if (bankdetail.status) {
    toast.success(bankdetail.message)
  }
  else {
    toast.error(bankdetail.message)
  }
  return bankdetail
};

import {
    Feedback
} from "./service";
import {
    SET_FEEDBACK_LIST,
    SET_FEEDBACK_LIST_TOTAL_PAGE,
    isLoading,
} from "../../../constants/actionConstants";
import {
    toast
} from "react-toastify";

// export const getMedicineList = (params) => async (dispatch) => {
//     dispatch(isLoading(true));
//     let getItemList = await Feedback.getMedicineList(params);
//     dispatch({
//         type: SET_MEDICINE_LIST,
//         payload: getItemList.data,
//     });
//     dispatch({
//         type: SET_MEDICINE_TOTAL_PAGE,
//         payload: getItemList.totalRecord,
//     });
//     dispatch(isLoading(false));
// };
export const getFeedbackList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getFeedbackList = await Feedback.getFeedbackList(params);
    console.log("---------", getFeedbackList);
    dispatch({
        type: SET_FEEDBACK_LIST,
        payload: getFeedbackList.data,
    });
    dispatch({
        type: SET_FEEDBACK_LIST_TOTAL_PAGE,
        payload: getFeedbackList.totalCount,
    });

    dispatch(isLoading(false));
};


export const deleteFeedback = (id) => async (dispatch) => {
    dispatch(isLoading(true))
    let deleteMedicne = await Feedback.deleteFeedback(id);
    dispatch(isLoading(false))
    toast.success(deleteMedicne.message)
    return deleteMedicne
};
import { SET_INVOICE_LIST_TOTAL_PAGE, SET_INVOICE_LIST } from "../../constants/actionConstants";

const initialState = {
  InvoiceList: [],
  InvoiceTotalPage: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_LIST:
      return { ...state, InvoiceList: action.payload };
    case SET_INVOICE_LIST_TOTAL_PAGE:
      return { ...state, InvoiceTotalPage: action.payload };
    default:
      return state;
  }
};
// import { SET_FEEDBACK_LIST_TOTAL_PAGE, SET_FEEDBACK_LIST } from "../../constants/actionConstants";

// const initialState = {
//   FeedbackList: [],
//   FeedbackTotalPage: 0
// };

// // eslint-disable-next-line import/no-anonymous-default-export
// export default (state = initialState, action) => {
//   switch (action.type) {
//     case SET_FEEDBACK_LIST:
//       return { ...state, FeedbackList: action.payload };
//     case SET_FEEDBACK_LIST_TOTAL_PAGE:
//       return { ...state, FeedbackTotalPage: action.payload };
//     default:
//       return state;
//   }
// };

import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';


function DownloadDoctorData() {
  const { ItemList } = useSelector((state) => state.users);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Doctor Data',
  });
useEffect(()=>{
handlePrint()
})
  return (
    <div ref={componentRef} style={{ width: '100%', height: '100%', padding: '20px' }}>
    <div style={{ marginBottom: '20px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Doctor Data</h1>
      <Button onClick={handlePrint} variant="primary" style={{ float: 'right' }}>Print</Button>
    </div>
    <table className="table table-striped">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Registration Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Gender</th>
            <th>State</th>
            <th>City</th>
            <th>Specialization</th>
            <th>Charges per Appointment</th>
            <th>Address</th>
            <th>Status</th>
            <th>Total Appointment</th>
            <th>Total Income</th>
          </tr>
        </thead>
        <tbody>
          {ItemList.map((doctor, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{doctor.registrationDate}</td>
              <td>{doctor.name}</td>
              <td>{doctor.email}</td>
              <td>{doctor.mobile}</td>
              <td>{doctor.gender}</td>
              <td>{doctor.state}</td>
              <td>{doctor.city}</td>
              <td>{doctor.specialization}</td>
              <td>{doctor.chargesPerAppointment}</td>
              <td>{doctor.address}</td>
              <td>{doctor.status}</td>
              <td>{doctor.appointmentCount}</td>
              <td>{doctor.totalIncome}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <Button onClick={handlePrint}>Print</Button> */}
    </div>
  );
}

export default DownloadDoctorData;

import React, { useState } from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Howl, Howler } from 'howler';



const TableColumn = ({
  handleEdit,
  handleReject,
  HandlejoinMetting,
  HandlestartMetting,
  HandlestartMettin,
  handleCall,
  downloadPrescription,
  handleComplent,
  DisconnectMetting,
  handleSchedileappoinment,
  handleFeedback,
  handleCancel,
  callInProgress,
  openAppoinmentModal,
  pageNo = 0,
  size = 10,
}) => {
  const role = storage.getUserRole();


  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
      dataField: "createdAt",
      text: "Date & Time",
      formatter: (cell, row, rowIndex) => {
        const indianDateTime = moment(cell).utcOffset('+05:30').format('DD-MM-YYYY hh:mm A');
        return indianDateTime;
      },
    },
    {
      dataField: "appointmentNumber",
      text: "Appointment Id",
      sort: false,
      formatter: (cell, row) => {
        return <a href="#0" onClick={() => openAppoinmentModal(row)}>{cell}</a>;
      },
    },
    {
      dataField: "expert.role",
      hidden: role === ROLES.DOCTOR || role === ROLES.FRANCHISE || role === ROLES.CORDINATOR,
      text: "User Type",
      formatter: (cell, row, rowIndex) => {
        if (cell === "FRANCHISE") {
          return "E-clinic"
        }
        return cell;
      },
    },
    {
      dataField: "patientName",
      text: "Patient Name",
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "age",
      text: "Age",
      formatter: (cell, row, rowIndex) => {
        const years = cell;
        const months = row.months;
        if (years && months) {
          return `${years} Year ${months} Month`;
        } else if (years && !months) {
          return `${years} Years`;
        } else if (!years && months) {
          return `${months} Month`;
        } else {
          return '';
        }
      },
    },

    {
      dataField: "expert.mobile",
      text: "User Mobile",
      sort: false,
      hidden: role === ROLES.DOCTOR || role === ROLES.FRANCHISE,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "doctor",
      text: "Doctor Name",
      hidden: ROLES.DOCTOR === role ? true : false,
      formatter: (cell, row, rowIndex) => {
        return cell && cell.name;
      },
    },
    {
      dataField: "doctor.mobile",
      text: "Doctor Mobile",
      sort: false,
      hidden: role === ROLES.DOCTOR || role === ROLES.FRANCHISE || role === ROLES.PATIENT,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "mode",
      text: "Consultatsion Mode",
      sort: false,
      formatter: (cell, row) => {
        if (cell === "Offline") {
          return "Clinic Visit";
        } else {
          return "Video Call";
        }
      },
    },
    {
      dataField: "paymentMode",
      text: "Payment Mode",
      hidden: ROLES.FRANCHISE === role ? true : false,
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      hidden: role === ROLES.FRANCHISE,
      sort: false,
      formatter: (cell, row) => {
        return (
          <span
            class={
              cell === "Paid"
                ? `custom-badge status-green`
                : `custom-badge status-red`
            }
          >
            {cell}
          </span>
        );
      },
    },

    {
      dataField: "doctor",
      text: "Speciality",
      hidden: role === ROLES.DOCTOR,
      formatter: (cell, row, rowIndex) => {
        return cell && cell.specialization;
      },
    },
    {
      dataField: "dateTime",
      text: "Appointment Date",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <span className="custom-badge" style={{ background: "#E5FAF3", fontWeight: "400", border: "1px solid #00CE7C" }}>{`${moment(cell).format("DD/MM/YYYY")} - ${row.selectedTimeSlot ?? ""}`}</span>
          </>
        );
      },
    },
    {
      dataField: "status",
      text: "Appointment Status",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <span
              class={
                cell === "confirmed" || cell === "completed" || cell === "prescription-ready"
                  ? `custom-badge status-green`
                  : `custom-badge status-red`
              } style={{ fontWeight: "500" }}
            >
              {cell}
            </span>
          </>
        );
      },
    },


    {
      dataField: "isPrescription",
      text: "Prescription",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <span
              className="fw-bold"
            >
              {cell}
            </span>
          </>
        );
      },
    },
    // {
    //   dataField: "view",
    //   text: "Detail",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     return (
    //       <button className="btn btn-success"onClick={() => openModal(row)}>
    //         View
    //       </button>
    //     );
    //   },
    // },

    {
      dataField: "feedbackId.description",
      text: "Feedback",
      hidden: role === ROLES.DOCTOR || role === ROLES.FRANCHISE || role === ROLES.PATIENT,
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "complaintId.details",
      text: "Complaint",
      hidden: role === ROLES.DOCTOR || role === ROLES.FRANCHISE || role === ROLES.PATIENT,
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },

    {
      dataField: "_id",
      text: "Action",
      // hidden: ROLES.CORDINATOR == role ? true : false,
      formatter: (cell, row, rowIndex, doctorId) => {
        if (row.callInprogress) {
          // var sound = new Howl({
          //   src: ['sound.webm', 'sound.mp3']
          // });

          // // Clear listener after first call.
          // sound.once('load', function () {
          //   sound.play();
          // });
          console.log("call in ", row.callInprogress);
          console.log("call in pause-----------", row);
          callInProgress('play')
        }
        return (
          <>
            <div className="btn-group">
              {ROLES.DOCTOR === role &&
                row.status === "pending" &&
                ROLES.CORDINATOR !== role && (
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => handleEdit(cell, "confirmed")}
                  >
                    Accept
                  </button>
                )}
              {row.mode === "Video" && (
                <>
                  {
                    row &&
                    ((
                      // row.status === "confirmed" ||
                      // row.status === "Follow-up") &&
                      (row.status === "confirmed" ||
                        row.status === "completed" ||
                        row.status === "Follow-up")) ||
                      row.status === 'prescription-ready' ||
                      role === ROLES.DOCTOR ||
                      role === ROLES.CORDINATOR)
                    && (
                      <>
                        {/* <a
                          className={row.callInprogress ? "btn btn-sm btn-info text-white blink" : "btn btn-sm btn-info text-white"}
                          href="#0"
                          onClick={() => HandlejoinMetting(cell)}
                        >
                          {row.callInprogress
                            ? "Call In Progress"
                            : ROLES.DOCTOR === role ? "Start Call" : "Join Call"}
                        </a> */}

                        <div>
                          {row.callInprogress ? (
                            <div className="btn btn-sm btn-info text-white blink" onClick={() => HandlejoinMetting(cell)} >
                              Call In Progress
                            </div>
                          ) : (
                            <div>
                              {ROLES.DOCTOR === role ? (
                                <div
                                  className="btn btn-sm btn-info text-white"
                                  onClick={() => HandlestartMetting(cell)}
                                >
                                  Start Call
                                </div>
                              ) : (
                                <div
                                  className="btn btn-sm btn-info text-white"
                                // onClick={() => HandlejoinMetting(cell)}
                                >
                                  Join Call
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )
                  }

                  {((row &&
                    (row.status === "confirmed" ||
                      row.status === "completed" ||
                      row.status === "Follow-up")) ||
                    row.status === 'prescription-ready' ||
                    role === ROLES.DOCTOR ||
                    role === ROLES.CORDINATOR) && (
                      <Link
                        className="btn btn-warning btn-sm"
                        to={`/appointment/${cell}`}
                      >
                        History
                      </Link>
                    )}


                  {((row &&
                    (row.status === "confirmed" ||
                      row.status === "completed" ||
                      row.status === "Follow-up")) ||
                    row.status === 'prescription-ready' ||
                    role === ROLES.DOCTOR ||
                    role === ROLES.CORDINATOR) && (
                      <Link
                        className="btn btn-success btn-sm"
                        to={`/chat/${cell}`}
                      >
                        Chat
                      </Link>
                    )}


                  {((ROLES.PATIENT === role || ROLES.FRANCHISE === role || ROLES.DOCTOR === role) && (row.status === "confirmed" || row.status === "pending")) && (
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => handleSchedileappoinment(row)}
                    >
                      Rechedule
                    </button>
                  )}
                  {((ROLES.PATIENT === role || ROLES.FRANCHISE === role) && (row.status === "confirmed" || row.status === "pending")) && (
                    <button
                      className="btn btn-sm btn-light"
                      onClick={() => handleCancel(cell, "cancelled")}
                    >
                      Appoinment cancel
                    </button>
                  )}
                  {((ROLES.PATIENT === role || ROLES.FRANCHISE === role) && (row.status === "completed")) && (
                    <button
                      className="btn btn-sm btn-light"
                      onClick={() => handleFeedback(row)}
                    >
                      Feedback
                    </button>
                  )}

                  {ROLES.DOCTOR === role &&
                    row.status === "pending" &&
                    ROLES.CORDINATOR !== role && (
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleReject(cell, "reject")}
                      >
                        Reject
                      </button>
                    )}

                  {row && ((row.isPrescription === "ready" || row.status === "completed") && ROLES.ADMIN !== role) && (
                    <button
                      className="btn btn-info"
                      onClick={() => downloadPrescription(cell)}
                    >
                      Prescription
                    </button>
                  )}

                  {((ROLES.PATIENT === role || ROLES.FRANCHISE === role) && row.status === "completed") && (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleComplent(cell)}
                    >
                      Raise Issue
                    </button>
                  )}
                </>
              )}
              {ROLES.DOCTOR === role &&
                (row.status === "confirmed" || row.status === "Follow-up" || row.status === 'prescription-ready') &&
                ROLES.CORDINATOR !== role && (
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => handleEdit(cell, "completed")}>
                    Complete
                  </button>
                )}
            </div>
          </>
        );
      },
    },
  ];
};

export default TableColumn;
import { SET_COMPLAINT_LIST,SET_COMPLAINT_TOTALRECORD,SET_COMPLAINT_TOTAL_PAGES } from "../../constants/actionConstants";

const initialState = {
    ItemList: [],
    ItemTotalPage: 0,
  ComplaintTotalRecord:0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPLAINT_LIST:
      return { ...state,ItemList: action.payload };
    case SET_COMPLAINT_TOTALRECORD:
      return { ...state, ItemTotalPage: action.payload };
    case SET_COMPLAINT_TOTAL_PAGES:
        return {...state,ComplaintTotalRecord:action.payload}
    default:
      return state;
  }
};

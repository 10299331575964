import api from "../../../dependencies/utils/Api";

import axios from 'axios';



import { APPOINTMENT, MEDICINE, APPOINTMENT_HISTORY, APPOINTMENT_TIME_SLOT, FEEDBACK, APPOINTMENT_CANCLE, APPOINTMENT_RESCHEDULE, APPOINTMENT_REJECT, CREATE_COMPLENT, FILEUPLOAD, GET_DOCTORS, PRESCRIPTION, APPOINTMENT_CALL_STATUS, FOLLOW_UP_SCHEDULE, PRESCRIPTION_BY_ID, PRESCRIPTION_APPOINTMENT_ID, SENDNOTIFICATION, AGORA_GENERATE_TOKEN, CheckisLoggedInUrl } from "../../../constants/ApplicationUrl";


export const API_URL = () => process.env.REACT_APP_API_BASE;

export const appointmentService = {
    // getItemListWithStatus(params) {
    //     return api.GET(`${APPOINTMENT}?page=${params.page}&size=${params.limit}&status=${params.status}`).then((response) => {
    //         // Check if the response or data is undefined or lacks the expected structure
    //         if (!response || !response.data || typeof response.data !== 'object') {
    //             // Return an object with default values
    //             return { message: '', data: [], totalAppointments: 0 };
    //         }

    //         // Destructure the response and provide default values if keys are missing
    //         const { data: { message = '', status = false, data = [], totalAppointments = 0 } } = response;

    //         // Return the extracted data
    //         return { message, status, data, totalAppointments };
    //     });
    // },


    getItemList(params) {
        console.log(params, "param")
        return api.GET(`${APPOINTMENT}?page=${params.page}&size=${params.limit}&fromDate=${params.fromDate}&toDate=${params.toDate}&status=${params.status}&paymentStatus=${params.paymentStatus}&month=${params.month}&year=${params.year}&state=${params.state}&city=${params.city}&finalStatus=${params.finalStatus}&appointmentNumber=${params.appointmentNumber}`).then((response) => {
            // Check if the response or data is undefined or lacks the expected structure
            if (!response || !response.data || typeof response.data !== 'object') {
                // Return an object with default values
                return { message: '', data: [], totalAppointments: 0 };
            }

            // Destructure the response and provide default values if keys are missing
            const { data: { message = '', status = false, data = [], totalAppointments = 0 } } = response;

            // Return the extracted data
            return { message, status, data, totalAppointments };
        });
    },

    sendNotification(obj) {
        return api.POST(`${SENDNOTIFICATION}`, obj).then((response) => {
            // if (!response || !response.data || typeof response.data !== 'object' || !('message' in response.data) || !('status' in response.data) || !('data' in response.data)) {
            //     return Promise.reject(new Error('Invalid API response format'));
            // }
            const { data: { message = '', status, data } = {} } = response
            if (status) {
                return { message, data }
            } else {

            }
        })
    },

    loggedInCheck() {
        return api.GET(`${CheckisLoggedInUrl}`).then((response) => {
            if (!response || !response.data || typeof response.data !== 'object') {
                // Return an object with default values
                return { message: '', status: false };
            }

            const { data: { message, status } } = response
            if (status) {
                return { message, status }
            } else {
                return { message, status: false };
            }
        })
    },







    getItemById(params) {
        return api.GET(`${APPOINTMENT}/${params}`).then((response) => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                return data
            } else {
                return {}
            }
        })
    },
    getAlDoctors(params) {
        return api.GET(`${GET_DOCTORS}?page=${params.page}&limit=${params.limit}&name=${params.name}&state=${params.state}&specialization=${params.specialization}&city=${params.city}&pricesort=${params.pricesort}`, params).then((response) => {
            const { data: { message, status, data, totalItems, spacility } = {} } = response
            if (status) {
                return { message, data, totalItems, spacility }
            } else {

            }
        })
    },

    updateStatus(id, params) {
        return api.PUT(`${APPOINTMENT}/${id}`, params).then((response) => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {

            }
        })
    },

    rejectStatus(id, params) {
        return api.PUT(`${APPOINTMENT_REJECT}/${id}`, params).then((response) => {
            const { data: { message } = {}, status } = response
            if (status) {
                return { message, status }
            } else {

            }
        })
    },

    cancleStatus(id, params) {
        return api.PUT(`${APPOINTMENT_CANCLE}/${id}`, params).then((response) => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {
                return { message, status }
            }
        })
    },
    feedbackStatus(params, appointmentId) {
        params.appointmentId = appointmentId;
        return api.POST(FEEDBACK, params).then((response) => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {
                return { message, status }
            }
        })
    },


    // rejectStatus = async (id, data) => {
    //     try {
    //         const response = await axios.put(`${API_BASE_URL}/appointment-rejected/${id}`, data, {
    //             headers: {
    //                 'Authorization': `Bearer ${AUTH_TOKEN}`
    //             }
    //         });
    //         return response.data;
    //     } catch (error) {
    //         console.error('Error rejecting appointment:', error);
    //         throw error; // Rethrow the error to handle it further if needed
    //     }
    // };

    updateCallStatus(id, params) {
        return api.PUT(`${APPOINTMENT_CALL_STATUS}/${id}`, params).then((response) => {
            const { data: { message, status, appointment } = {} } = response
            if (status) {
                return { message, status, appointment }
            } else {

            }
        })
    },


    createItem(params) {
        return api.POST(APPOINTMENT, params).then((response) => {
            const { data: { message, status, appointment, amount } = {} } = response
            if (status) {
                return { message, appointment, status, amount }
            } else {
                return { message, status }
            }
        })
    },

    fileUplaod(params) {
        return api.POSTDATA(FILEUPLOAD, params).then((response) => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                return { message, data, status }
            } else {
                return { message, data, status }
            }
        })
    },
    addHistory(id, params) {
        return api.PUT(`${APPOINTMENT_HISTORY}/${id}`, params).then((response) => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {

            }
        })
    },

    // Prescriptin
    addPrescription(params) {
        return api.PUTDATA(PRESCRIPTION, params).then((response) => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                return { message, data, status }
            } else {

            }
        })
    },

    //get time slot by choosing date on appointment booking on home page
    getTimeSlot(params) {
        return api.POST(PRESCRIPTION, params).then((response) => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                return { message, data, status }
            } else {

            }
        })
    },


    UpdatePrescription(id, params) {
        return api.PUT(`${PRESCRIPTION}/${id}`, params).then((response) => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                return { message, data, status }
            } else {

            }
        })
    },

    createComplent(params) {
        return api.POST(`${CREATE_COMPLENT}/${params.appointmentId}`, params).then((response) => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                return { message, data, status }
            } else {

            }
        })
    },

    scheduleFollowUo(id, params) {
        return api.PUT(`${FOLLOW_UP_SCHEDULE}/${id}`, params).then((response) => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {

            }
        })
    },

    appoinmenttimeslot(doctorId, dateTime) {
        return api.POST(`${APPOINTMENT_TIME_SLOT}/${doctorId}`, dateTime).then(response => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                if (data.mode === 'Video') {
                    return data.mode;
                }
                else {
                }
                return { message, data };
            } else {

            }

        })
    },


    recheduleappoinment(params, Id) {
        return api.PUT(`${APPOINTMENT_RESCHEDULE}/${Id}`, params).then(response => {
            console.log("response rechedule appoinment", response);
            if (response.status === 200) {
                const { data: { message } = {}, status } = response;
                if (status) {
                    return { message, status };
                }
                else {
                    return { message, status };
                }
            }
            else {
                const { message, status } = response;
                if (status) {
                    return { message, status };
                }
                else {
                    return { message, status };
                }
            }
        });
    },


    getMedicineList(params) {
        return api.GET(`${MEDICINE}?page=${params.page}&size=${params.size}&filter=${params.filter}`).then((response) => {
            const { data: { message, status, data, totalRecord } = {} } = response
            if (status) {
                return { message, data, totalRecord }
            } else {

            }
        })
    },

    getPrescriptionById(id) {
        return api.DOWNLOADGETPDF(`${PRESCRIPTION_BY_ID}/${id}`)
    },

    getPrescriptionAptId(id) {
        return api.DOWNLOADGETPDF(`${PRESCRIPTION_APPOINTMENT_ID}/${id}`)
    },

    agoraGeneratetoken(params) {
        return api.POST(AGORA_GENERATE_TOKEN, params).then((response) => {
            console.log("response reing web ka", response);
            const { data: { message, status, tokenA } = {} } = response
            if (status) {
                return { message, tokenA, status }
            } else {

            }
        })
    }
}
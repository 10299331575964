import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({
  HandleEdit,
  pageNo = 0,
  size = 10,
  openModal,
  activateDeactivate
}) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return (pageNo - 1) * size + (rowIndex + 1);
      },
    },
    {
      dataField: "createdAt",
      text: "Registration date",
      sort: false,
      formatter: (cell, row) => {
        if (cell) {
          const createdAtDate = new Date(cell);
          const day = String(createdAtDate.getDate()).padStart(2, "0");
          const month = String(createdAtDate.getMonth() + 1).padStart(2, "0");
          const year = String(createdAtDate.getFullYear());
          const formattedDate = `${day}-${month}-${year}`;
          return formattedDate;
        } else {
          return "";
        }
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      formatter: (cell, row) => {
        return cell
      },
    },
   
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "gender",
      text: "Gender",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "position",
      text: "Position",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    ,
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row, rowIndex) => {
        if (cell == 'inactive') {
          return <label className="badge badge-danger">Deactivated</label>
        } else {
          return <label className="badge badge-success">Active</label>
        }
      },
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <button className="btn btn-info" onClick={() => HandleEdit(row)}>
              Edit
            </button>
            {row.status === "active" ? (
                <button
                  className="btn btn-danger"
                  onClick={() => activateDeactivate(cell, 'inactive')}
                >
                  Deactive
                </button>
              ) : (
                <button
                  className="btn btn-warning"
                  onClick={() => activateDeactivate(cell, 'active')}
                >
                  Activate
                </button>
              )}
          </>
        );
      },
    },
  ];
};

export default TableColumn;

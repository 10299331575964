import React, { useState } from 'react';
import './index.css';
import MainFooter from '../common/MainFooter';
import MainHeader from '../common/MainHeader';
import { Link } from 'react-router-dom';

function FAQ() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleAccordion = (tabNumber) => {
    if (tabNumber === 1) {
      setIsOpen1(!isOpen1);
      setIsOpen2(false); // Close other tab
    } else if (tabNumber === 2) {
      setIsOpen2(!isOpen2);
      setIsOpen1(false); // Close other tab
    }
  };

  return (
    <>
      <head>
        <title>Fever99-FAQ</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <MainHeader />
      <div className="breadcrumbs">
        <div className="wrapper" style={{ backgroundImage: `url("https://wordpress.themeholy.com/mediax/wp-content/uploads/2023/12/breadcumb-bg.jpg")` }}>
          <div className="page-header d-flex align-items-center">
            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center">
                  <h2>FAQ</h2>
                  <nav>
                    <div className="container">
                      <ol>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>FAQ</li>
                      </ol>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-4'>
        <div className="faq-container">
          <div className="faq-question" onClick={() => toggleAccordion(1)}>
            <div className="faq-question-text">
              <h5>1. What is Fever99.com and how does it work?</h5>
            </div>
            <div className={`faq-icon ${isOpen1 ? 'open' : 'closed'}`}>
              {isOpen1 ? '-' : '+'}
            </div>
          </div>
          {isOpen1 && (
            <div className="faq-answer">
              <p>Fever99.com is an online healthcare platform designed to securely store your medical and demographic data.</p>
              {/* Add more paragraphs for the answer if needed */}
            </div>
          )}
        </div>
        <div className="faq-container">
          <div className="faq-question" onClick={() => toggleAccordion(2)}>
            <div className="faq-question-text">
              <h5>2. Is the content on Fever99.com reliable?</h5>
            </div>
            <div className={`faq-icon ${isOpen2 ? 'open' : 'closed'}`}>
              {isOpen2 ? '-' : '+'}
            </div>
          </div>
          {isOpen2 && (
            <div className="faq-answer">
              <p>While we strive to provide accurate information, there may be inaccuracies or errors. Your use of the content is at your own risk.</p>
              {/* Add more paragraphs for the answer if needed */}
            </div>
          )}
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default FAQ;

import { DELETE_WITHDRAW, CREATE_WITHDREAW, GET_ALL_WITHDRAW, UPDATE_WITHDRAW } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"

export const TeamService = {
    getWithdrawList(params) {
        return Api.GET(`${GET_ALL_WITHDRAW}?page=${params.page}&size=${params.size}&fromDate=${params.fromDate}&toDate=${params.toDate}&status=${params.status}&type=${params.type}`)
            .then(response => {
                const { data: { message, withdrawals, totalWithdrawals, status } = {} } = response;
                if (status) {
                    return { status, message, totalWithdrawals, withdrawals };
                } else {
                    return { message, status };
                }
            })
    },

    withdraw(params) {
        return Api.POST(CREATE_WITHDREAW, params).then(response => {
            if (response.status === 200) {
                const { data: { message, status, data } = {} } = response
                if (status) {
                    return { message, status, data };
                }
                else {
                    return { message, status };
                }
            }
            else {
                return response
            }
        })
    },

    deletewithdraw(id) {
        return Api.DELETE(`${DELETE_WITHDRAW}/${id}`).then(response => {
            const { data: { message, data } = {}, status } = response
            if (status) {
                return { message, data, status }
            } else {

            }
        })
    },
    updatewithdraw(id, params) {
        return Api.PUT(`${UPDATE_WITHDRAW}/${id}`, params).then(response => {
            const { data: { message, status, data } = {} } = response
            if (status) {
                return { message, data, status }
            } else {

            }
        })
    }

}
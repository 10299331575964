import { ACCOUNTINFO } from "../../../constants/ApplicationUrl";
import api from "../../../dependencies/utils/Api";

export const ProfileService = {

  accountupdate(params) {
    return api.POST(ACCOUNTINFO, params).then((response) => {
      const { data: { status, message } = {} } = response;
      return { status, message }
    });
  },
};

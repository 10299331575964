import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({ pageNo = 0, size = 10 }) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
      dataField: "transactions.timestamp",
      text: "Date & Time",
      formatter: (cell, row, rowIndex) => {
        return moment(cell).format("DD/MM/YYYY h:mm:ss a");
      },
    },
    {
        dataField: "userDetails.name",
        text: "User Name",
        formatter: (cell, row, rowIndex) => {
          return cell;
        },
      },
      {
        dataField: "userDetails.role",
        text: "User Role",
        formatter: (cell, row, rowIndex) => {
          return cell;
        },
      },

      {
        dataField: "transactions.type",
        text: "Transaction Type",
        formatter: (cell, row, rowIndex) => {
          return cell;
        },
      },
    {
      dataField: "transactions.amount",
      text: "Amount",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },

    {
      dataField: "transactions.remainingBalance",
      text: "Balance",
      formatter: (cell, row, rowIndex) => {
        return cell;
            },
    },

    {
      dataField: "transactions.message",
      text: "Message",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
   
   
  ];
};

export default TableColumn;

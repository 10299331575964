





// import React from 'react';
// import CanvasJSReact from '@canvasjs/react-charts';

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const TotalIncome = (props) => {
//   const { data: { totalMonthlyIncome } } = props;

//   const getMonthName = (monthValue) => {
//     const monthDate = new Date(2023, monthValue - 1, 1); // Using a specific year (2023) and setting the day to 1
//     const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
//     return monthName;
//   }

//   let dataSet = []
//   if (totalMonthlyIncome) {
//     dataSet = totalMonthlyIncome.map((item) => {
//       return { label: getMonthName(item.x), y: item.y }
//     })
//   }
//   const options = {
//     title: {
//       text: 'Total Income',
//     },
//     data: [
//       {
//         type: 'line', // This is already set to line chart
//         dataPoints: dataSet,
//       },
//     ],
//   };

//   return (
//     <div>
//       {
//         totalMonthlyIncome && (
//           <CanvasJSChart options={options} />
//         )
//       }
//     </div>
//   );
// };

// export default TotalIncome;










import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const TotalIncome = (props) => {
  const chartContainer = useRef(null);
  const { data: { totalMonthlyIncome } } = props;

  useEffect(() => {
    if (totalMonthlyIncome && chartContainer.current) {
      const chartData = {
        labels: totalMonthlyIncome.map(item => getMonthName(item.x)),
        datasets: [{
          label: 'Total Income',
          data: totalMonthlyIncome.map(item => item.y),
          backgroundColor: 'rgba(54, 162, 235, 0.2)', // Background color for the line
          borderColor: 'rgba(54, 162, 235, 1)', // Border color for the line
          borderWidth: 1, // Border width for the line
          fill: false, // Disable fill to show line chart
        }]
      };

      const chartOptions = {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Total Income'
          }
        }
      };

      const lineChart = new Chart(chartContainer.current, {
        type: 'line',
        data: chartData,
        options: chartOptions
      });

      return () => lineChart.destroy(); // Cleanup on unmount
    }
  }, [totalMonthlyIncome]);

  const getMonthName = (monthValue) => {
    const monthDate = new Date(2023, monthValue - 1, 1); // Using a specific year (2023) and setting the day to 1
    const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
    return monthName;
  };

  return <canvas ref={chartContainer} />;
};

export default TotalIncome;


















// import React, { useEffect, useRef } from 'react';
// import Chart from 'chart.js/auto';

// const TotalIncome = (props) => {
//   const chartContainer = useRef(null);
//   const { data: { totalMonthlyIncome } } = props;

//   useEffect(() => {
//     if (totalMonthlyIncome && chartContainer.current) {
//       const chartData = {
//         labels: totalMonthlyIncome.map(item => getMonthName(item.x)),
//         datasets: [{
//           label: 'Total Income',
//           data: totalMonthlyIncome.map(item => item.y),
//           backgroundColor: 'rgba(54, 162, 235, 0.2)', // Background color for the bars
//           borderColor: 'rgba(54, 162, 235, 1)', // Border color for the bars
//           borderWidth: 1, // Border width for the bars
//         }]
//       };

//       const chartOptions = {
//         indexAxis: 'x', // Change the axis to display bars horizontally
//         scales: {
//           y: {
//             beginAtZero: true
//           }
//         },
//         plugins: {
//           legend: {
//             display: true,
//             position: 'top',
//           },
//           title: {
//             display: true,
//             text: 'Total Income'
//           }
//         }
//       };

//       const barChart = new Chart(chartContainer.current, {
//         type: 'bar', // Change chart type to 'bar'
//         data: chartData,
//         options: chartOptions
//       });

//       return () => barChart.destroy(); // Cleanup on unmount
//     }
//   }, [totalMonthlyIncome]);

//   const getMonthName = (monthValue) => {
//     const monthDate = new Date(2023, monthValue - 1, 1); // Using a specific year (2023) and setting the day to 1
//     const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
//     return monthName;
//   };

//   return <canvas ref={chartContainer} />;
// };

// export default TotalIncome;





// import React from 'react';
// import CanvasJSReact from '@canvasjs/react-charts';

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const TotalIncome = (props) => {
//   const { data: { totalMonthlyIncome } } = props;

//   const getMonthName = (monthValue) => {
//     const monthDate = new Date(2023, monthValue - 1, 1); // Using a specific year (2023) and setting the day to 1
//     const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
//     return monthName;
//   }

//   let dataSet = []
//   if (totalMonthlyIncome) {
//     dataSet = totalMonthlyIncome.map((item) => {
//       return { label: getMonthName(item.x), y: item.y }
//     })
//   }
//   const options = {
//     title: {
//       text: 'Total Income',
//     },
//     data: [
//       {
//         type: 'bar', // Change type to 'bar' for bar chart
//         dataPoints: dataSet,
//       },
//     ],
//   };

//   return (
//     <div>
//       {
//         totalMonthlyIncome && (
//           <CanvasJSChart options={options} />
//         )
//       }
//     </div>
//   );
// };

// export default TotalIncome;















// import React from 'react';
// import CanvasJSReact from '@canvasjs/react-charts';

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const TotalIncome = (props) => {
//   const { data: { totalMonthlyIncome } } = props;

//   const getMonthName = (monthValue) => {
//     const monthDate = new Date(2023, monthValue - 1, 1); // Using a specific year (2023) and setting the day to 1
//     const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
//     return monthName;
//   }

//   let dataSet = []
//   if (totalMonthlyIncome) {
//     dataSet = totalMonthlyIncome.map((item) => {
//       return { label: getMonthName(item.x), y: item.y }
//     })
//   }
//   const options = {
//     title: {
//       text: 'Total Income',
//     },
//     data: [
//       {
//         type: 'pie', // Change type to 'pie' for pie chart
//         indexLabel: "{label}: {y}", // Show label and value in the pie slices
//         dataPoints: dataSet,
//       },
//     ],
//   };

//   return (
//     <div>
//       {
//         totalMonthlyIncome && (
//           <CanvasJSChart options={options} />
//         )
//       }
//     </div>
//   );
// };

// export default TotalIncome;



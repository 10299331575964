import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createItem } from "../dependiencies/action";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import DropzoneComponent from "./DropzoneComponent";
import { ROLES } from "../../../constants/role";
import { Link } from "react-router-dom";
import { storage } from "../../../dependencies/store/storage";
import { createPaymentSession, encryptCCAvenueData, getWallet, GetTimeSlot } from "../../Dashboard/dependencies/action";
import DatePicker from "react-datepicker";
import { FaStar } from "react-icons/fa6";
import { HANDLE_RESPONSE } from "../../../constants/ApplicationUrl";


function AddModal(props) {

  const { extractedOfflineTimes, extractedOnlineTimes, status_timeslot, message_Timeslot } = useSelector((store) => store.login);


  const { showModal, handleClose, data, mode } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, wallet } = useSelector((state) => state.login);
  const [form, setForm] = useState({ paymentMode: "Online", heightUnit: 'cm' });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [error, setError] = useState({});
  const role = storage.getUserRole();
  const formRef = useRef(null);
  const [encRequest, setEncRequest] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const { stateList } = useSelector((state) => state.stateCity);
  const [city, setCity] = useState([]); // Define city state

  const handleUploadFile = (file) => {
    setUploadedFiles((prevUploadedFiles) => [
      ...prevUploadedFiles,
      { fileName: file },
    ]);
  };
  //convert date itme into dd yy mm format
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear(); // Getting the full year
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  }

  const [onlineSlot, setOnlineSlot] = useState([]);
  const [OfflineSlot, setOfflineSlot] = useState([]);



  const handleInputChange = (name, value) => {
    const formattedDate = formatDate(value);
    console.log(name, value, "yyyyyyyyyyyy");
    setForm({ ...form, [name]: value });
    dispatch(GetTimeSlot(formattedDate, data._id))
      .then(res => {
        const { extractedOfflineTimes, extractedOnlineTimes, message, status } = res
        setOnlineSlot(extractedOnlineTimes)
        setOfflineSlot(extractedOfflineTimes)
        if (status && data != undefined) {
        }
      })
  };

  const handleCityChange = (value) => {
    setForm({ ...form, city: value });
    console.log(value, "value-----");
  };

  const sortedStateList = stateList.slice().sort((a, b) => {

    const stateA = a.state.toUpperCase();
    const stateB = b.state.toUpperCase();

    if (stateA < stateB) {
      return -1;
    }
    if (stateA > stateB) {
      return 1;
    }
    return 0;
  });

  const handleStateChange = (e) => {
    let stateName = e.target.value;
    let selectedState = stateList.find((state) => state.state === stateName);
    if (selectedState) {
      let sortedCities = selectedState.city.slice().sort((a, b) => {
        const cityA = a.toUpperCase();
        const cityB = b.toUpperCase();

        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }
        return 0;
      });
      setCity(sortedCities);
      setForm({ ...form, state: stateName });
      console.log("state", stateName);
    }
  };
  useEffect(() => {
    if (!isEmpty(data)) {
      setForm({
        ...form,
        doctorId: data._id,
        timeSlot: data.timeSlot,
        timeSlotoffline: data.timeSlotoffline,
      });
    }
  }, [data, form]);

  const validator = () => {

    let errors = {};
    if (!form.selectedTimeSlot) {
      errors = { ...errors, selectedTimeSlot: "This field is required!" };
    }
    if (!form.dateTime) {
      errors = { ...errors, dateTime: "This field is required!" };
    }
    if (!form.patientName) {
      errors = { ...errors, patientName: "This field is required!" };
    }
    if (!form.months) {
      errors.months = "Months are required";
    } else if (form.months < 1 || form.months > 12) {
      errors.months = "Months must be between 1 and 12";
    }
    if (!form.gender) {
      errors = { ...errors, gender: "This field is required!" };
    }
    if (!form.heightUnit) {
      errors = { ...errors, heightUnit: "This field is required!" };
    }
    setError(errors);
    return errors;
  };

  const createAppointment = async (e) => {
    e.preventDefault();

    let validate = validator();

    if (!isEmpty(validate)) {
      return false;
    }

    if (wallet < data.serviceCharge && role === ROLES.FRANCHISE) {
      setError({
        wallet:
          "Sorry! you don't have sufficient amount to book an appointment!",
      });
      return false;
    }
    let newForm = { ...form }


    console.log('all data to submit in backed', newForm)
    // return

    let res = await dispatch(
      createItem({
        ...newForm,
        expertId: user._id,
        files: uploadedFiles,
        mode: mode,
      })
    );

    if (res.status) {
      // setForm({});
      if (role === ROLES.FRANCHISE) {
        handleClose(false);
        navigate("/appointments");
      } else {
        if (form && form.paymentMode == "Offline") {
          handleClose(false);
          navigate("/appointments");
        } else {
          if (res && res.appointment && res.appointment.serviceChargepatient) {
            let req = {
              amount: res.appointment.serviceChargepatient,
              name: user.name,
              address: user.address,
              email: user.email,
              mobile: user.mobile,
              payload: {
                email: user.email,
                reidrect_url: `${HANDLE_RESPONSE}`,
                // reidrect_url: 'http://127.0.0.1:8000/api/handle-response',
                appointmentId: res.appointment._id
              }
            }
            setAccessCode(process.env.REACT_APP_CCAVENUE_ACCESS_CODE);
            dispatch(encryptCCAvenueData(req)).then((res) => {
              const { status, data } = res
              setEncRequest(data)
              setTimeout(async () => {
                await formRef.current.submit();
              }, 1000);
            })
          } else {
            handleClose(false);
            navigate("/appointments");
          }
        }
      }
    }
  };

  useEffect(() => {
    calculateBMI();
  }, [form.height, form.weight, form.heightUnit, form.feet, form.inch]);

  const calculateBMI = () => {
    const weight = parseFloat(form.weight);
    let heightInCm;

    if (form.heightUnit === 'cm') {
      heightInCm = parseFloat(form.height);
    } else {
      const totalInche = (parseInt(form.feet) * 12) + parseInt(form.inch);
      heightInCm = totalInche * 2.54;
    }
    const bmi = (weight / ((heightInCm / 100) * (heightInCm / 100))).toFixed(1);
    setForm({
      ...form,
      bmi: bmi
    });
  };


  return (
    <>
      <Modal
        size="xl"
        show={showModal}
        onHide={() => handleClose(false)}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Appointment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && error.wallet && (
            <p className="text-danger">{error.wallet}</p>
          )}
          <form
            ref={formRef}
            id="nonseamless"
            method="post"
            name="redirect"
            action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
          // action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
          >
            <input
              type="hidden"
              id="encRequest"
              name="encRequest"
              value={encRequest}
            ></input>
            <input
              type="hidden"
              name="access_code"
              id="access_code"
              value={accessCode}
            ></input>
          </form>

          <form onSubmit={(e) => createAppointment(e)}>
            <div className="row">
              <div className="form-group mt-2 col-md-4">
                <label>Date: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "16px" }} /></label>
                <DatePicker
                  className="form-control"
                  minDate={new Date()}
                  title="Select Date"
                  name="dateTime"
                  selected={form.dateTime}
                  placeholderText="Select Date"
                  onChange={(date) => {
                    const utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                    handleInputChange("dateTime", utcDate);
                    let validate = validator();
                    if (!isEmpty(validate)) {
                      return true;
                    }
                  }}
                />
                {error && error.dateTime && (
                  <span className="text-danger">{error.dateTime}</span>
                )}
              </div>
              <div className="form-group mt-2 col-md-4">
                {/* <label>Select Online Slot: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "16px" }} /></label>
                <select
                  name="selectedTimeSlot"
                  className="form-select"
                  value={form.selectedTimeSlot}
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }>
                  <option value="">{form.selectedTimeSlot ? form.selectedTimeSlot : '--Select Slot--'}</option>
                  {mode === "Video" &&
                    onlineSlot &&
                    onlineSlot.map((d, index) => (
                      console.log('this is ',d.label)
                      <option value={d.label} key={index}>{d.label}</option>
                    ))}
                </select> */}

                <label>
                  Select Online Slot:
                  <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "16px" }} />
                </label>
                <style>{`
                   .no-slot {
                      color: red;
                    }
                     .yes-slot {
                     color: green;
                   }
                `}</style>
                <select
                  name="selectedTimeSlot"
                  className="form-select"
                  value={form.selectedTimeSlot}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                >
                  <option value="">{form.selectedTimeSlot ? form.selectedTimeSlot : '--Select Slot--'}</option>
                  {mode === "Video" &&
                    onlineSlot &&
                    onlineSlot.map((d, index) => (
                      <option value={d.label} key={index}>{d.label}</option>
                    ))}
                </select>


                {mode !== "Video" &&
                  <>
                    <label>Select Offline Slot <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "16px" }} /></label>
                    <select
                      name="selectedTimeSlot"
                      className="form-select"
                      value={form.selectedTimeSlot}
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }>
                      <option value="">{form.selectedTimeSlot ? form.selectedTimeSlot : '--Select Slot--'}</option>
                      {OfflineSlot &&
                        OfflineSlot.map((d, index) => (
                          <option value={d.label} key={index}>{d.label}</option>
                        ))}
                    </select>
                  </>
                }
                {error && error.selectedTimeSlot && (
                  <span className="text-danger">{error.selectedTimeSlot}</span>
                )}
              </div>
              <div className="form-group mt-2 col-md-4">
                <label>Patient Name: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                <input
                  type="text"
                  name="patientName"
                  className="form-control"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  placeholder="Patient Name"
                />
                {error && error.patientName && (
                  <span className="text-danger">{error.patientName}</span>
                )}
              </div>

              <div className="form-group mt-2 col-md-6">
                <label>Patient Age: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="age"
                      min={1}
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                      className="form-control"
                      placeholder="Year"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="months"
                      min={1}
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                      className="form-control"
                      placeholder="Month"
                    />
                    {error && error.months && (
                      <span className="text-danger">{error.months}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group mt-2 col-md-6">
                <label style={{}}>Patient Gender: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
                <select
                  name="gender"
                  className="form-select"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }>
                  <option value="">--Select Gender--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="other">Other</option>
                </select>
                {error && error.gender && (
                  <span className="text-danger">{error.gender}</span>
                )}
              </div>
              <div className="col-lg-4 mt-2">
                <label>State: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "16px" }} /></label>
                <select
                  name="state"
                  value={form.state}
                  onChange={(e) => handleStateChange(e)}
                  className="form-select">
                  <option value="">Select State</option>
                  {sortedStateList &&
                    sortedStateList.map((itm, index) => (
                      <option value={itm.state} key={index}>
                        {itm.state}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-4 mt-2">
                <label>City: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "16px" }} /></label>
                <select
                  name="city"
                  value={form.city}
                  onChange={(e) => handleCityChange(e.target.value)}
                  className="form-select">
                  <option value="">Select City</option>
                  {city &&
                    city.map((cit, index) => (
                      <option value={cit} key={index}>
                        {cit}
                      </option>
                    ))}
                </select>
              </div>
              <label>Height ft & cm: <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "10px" }} /></label>
              <div className="input-group mt-2 col-md-6">
                {form.heightUnit === 'cm' && (
                  <input
                    type="text"
                    name="height"
                    min={1}
                    value={form.height}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    className="form-control"
                    placeholder="Height in cm"
                  />
                )}
                {form.heightUnit === 'ft' && (
                  <>
                    <input
                      type="text"
                      name="feet"
                      value={form.feet}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      className="form-control"
                      placeholder="Feet"
                    />
                    <input
                      type="text"
                      name="inch"
                      value={form.inch}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      className="form-control"
                      placeholder="Inch"
                    />
                  </>
                )}
                <select
                  name="heightUnit"
                  value={form.heightUnit}
                  onChange={(e) => {
                    handleInputChange(e.target.name, e.target.value);
                  }}
                  className="input-group-text">
                  <option value="cm">cm</option>
                  <option value="ft">ft</option>
                </select>
              </div>

              {error && error.height && <span className="text-danger">{error.height}</span>}
              <div className="form-group mt-2 col-md-6">
                <label>
                  Weight (in kg): <FaStar className="text-danger" style={{ fontSize: "7px", marginBottom: "16px" }} />
                </label>
                <input
                  type="number"
                  name="weight"
                  min={1}
                  value={form.weight}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                  className="form-control"
                  placeholder="Weight (in kg)"
                />
              </div>
              {form.bmi && <p className="mt-2">BMI: {form.bmi}</p>}
              {form && !form.weight && <span className="text-danger">{error.weight}</span>}

              <div className="col-md-6">
                <label>Respiratory Rate</label>
                <input
                  type="number"
                  name="respiratoryRate"
                  value={form.respiratoryRate}
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  className="form-control"
                  placeholder="RR"
                />
              </div>
            </div>
            {mode === "Video" && (
              <>
                <hr />
                <div className="row">
                  <div className="form-group mt-2 col-md-4">
                    <label>
                      BP <b>mm of Hg</b> (Optional)
                    </label>
                    <input
                      name="bp"
                      type="text"
                      className="form-control"
                      placeholder='Eg: "120/80" mm of Hg'
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group mt-2 col-md-4">
                    <label>
                      Pulse <b>Per minute</b> (Optional)
                    </label>
                    <input
                      name="pulse"
                      type="text"
                      className="form-control"
                      placeholder='Eg: "80" Per Minute'
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group mt-2 col-md-4">
                    <label>
                      Body Temperature <b>℉</b>{" "}
                      (Optional){" "}
                    </label>
                    <input
                      name="bodyTemperature"
                      type="text"
                      className="form-control"
                      placeholder="Eg: 98 ℉"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group mt-2 col-md-6">
                    <label>
                      S<sub>P</sub>O<sub>2</sub> % (Optional)
                    </label>
                    <input
                      name="oxigne"
                      type="text"
                      className="form-control"
                      placeholder="Eg: 98%"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group mt-2 col-md-6">
                    <label>
                      Fasting Sugar <b>mg/dL</b> (FBS)
                    </label>
                    <input
                      name="suger1"
                      type="text"
                      className="form-control"
                      placeholder="Fasting Sugar (mg/dL) (FBS)"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      } />
                  </div>
                  <div className="form-group mt-2 col-md-4">
                    <label>
                      Postprandial Sugar <b>mg/dL</b>{" "}
                      (PPBS)
                    </label>
                    <input
                      name="suger2"
                      type="text"
                      className="form-control"
                      placeholder="Postprandial Sugar (PPBS)"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group mt-2 col-md-4">
                    {/* <label>Hemoglobin A1c (HbA1c)</label> */}
                    <label>
                      Random Sugar <b>mg/dL</b>
                    </label>
                    <input
                      name="suger3"
                      type="text"
                      className="form-control"
                      placeholder="Random Sugar (mg/dL)"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group mt-2 col-md-4">
                    <DropzoneComponent
                      uploadedFile={(file) => handleUploadFile(file)}
                    />
                  </div>
                </div>
              </>
            )}

            {mode === "Video" && (
              <div className="form-group mt-2">
                <label>
                  Send You Medical History On What's App{" "}
                  <i class="fab fa-whatsapp" style={{ color: "#42df16" }}></i>{" "}
                  <Link href="https://wa.me/+916262808062" target="_blank">
                    Send Now
                  </Link>
                </label>
              </div>
            )}
            {role === ROLES.PATIENT && mode !== "Video" && (
              <div className="form-group mt-2">
                <label className="w-100">Payment Mode</label>
                <label className="w-100">
                  <input
                    type="radio"
                    name="paymentMode"
                    value="Online"
                    defaultChecked
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />{" "}
                  Pay Online
                </label>
                <label className="w-100">
                  <input
                    type="radio"
                    name="paymentMode"
                    value="Offline"
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />{" "}
                  Pay at Clinic
                </label>
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => createAppointment(e)}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default AddModal;


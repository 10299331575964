
import React, { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import userImg from "../../assets/img/user.jpg";
import Table from "../common/Table";
import TableColumn from "./dependiencies/TableColumn";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemListWithStatus,
  getItemList,
  getItemListWithLoader,
  getItemById,
  prescriptionAppointmentId,
  updateAppointment,
  rejectAppointment,
  cancleAppointment,
  updateCallStatus,
  sendNotificationObj,
  VideoCallToken,
} from "./dependiencies/action";
import RecheduleAppoinment from "./RecheduleAppoinment";
import FeedbackComplete from "./FeedbackComplete";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { ROLES } from "../../constants/role";
import { storage } from "../../dependencies/store/storage";
import SimplePeer from "simple-peer";
import RaiseComplent from "./RaiseComplent";
import "./apppointment.scss";
// import { Howl } from "howler";
// import soundSrc from "../../assets/beep.mp3";
import soundSrc from "../../assets/ringtone-web.mp3"
// import sounds from '../../assets/beep.mp3'
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AgoraUIKit from "agora-react-uikit";
import { isLoading } from "../../constants/actionConstants";
import { useModal } from "../UseModal/UseModal";
import AppointmentModal from "./AppointmentModal/AppointmentModal";
import DownloadAppointmentData from "./DownloadAppointmentData/DownloadAppointmentData";

import { Howl, Howler } from 'howler';


function Appointment(props) {

  const { Howl, Howler } = require('howler');

  // const sound = new Howl({
  //   src: [sounds],
  // })

  const {
    showModal: showAppointmentModal,
    selectedItem: selectedAppointment,
    openAppoinmentModal: handleOpenAppointmentModal,
    openModal: handleOpenDetailsModal,
    closeModal: handleCloseAppointmentModal

  } = useModal();
  const dispatch = useDispatch();
  const { ItemList, ItemTotalPage } = useSelector((state) => state.appointment);
  const { user } = useSelector((state) => state.login);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState({});
  const [stoDate, settoDate] = useState("");
  const [videoCall, setVideoCall] = useState(false);
  const [uid, setuid] = useState(Math.floor(Math.random() * 1000));
  const [selectedStatus, setSelectedStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [year, setYear] = useState('');
  const soundRef = useRef(null);
  const [month, setMonth] = useState('');
  const { stateList } = useSelector((state) => state.stateCity);
  console.log(stateList, "state")
  const [city, setCity] = useState([]); // Define city state
  const [additionalFilter, setAdditionalFilter] = useState({
    state: "",
    city: "",
  });
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  // Update month state when the user selects a month
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const [rtcProps, setRtcProps] = useState({
    appId: "b671aeda83ee48d582e9cc33f83b30cb",
    channel: "test",

  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  console.log(status, "sta")
  let finalStatus = status ?? "";
  let toady = "";

  if (status === "today") {
    finalStatus = "today";

    let today = new Date().toISOString();
    const day = new Date(today).getDate();
    const month = new Date(today).getMonth() + 1;
    const year = new Date(today).getFullYear();
    toady = `${year}-${month}-${day}`;
  }


  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    paymentStatus: "",
    status: selectedStatus,
    finalStatus: "" || finalStatus,
    state: "",
    city: "",
    appointmentNumber: ""
  });

  const navigate = useNavigate();
  const [printPage, setPrintPage] = useState(false)
  const handlePrint = () => {
    setPrintPage(true);
    navigate('/downloadAppointmentData');
  };
  const role = storage.getUserRole();
  const [raiseComplent, setRaiseComplent] = useState(false);
  const [RecheduleModal, setRecheduleModal] = useState(false);
  const [FeedbackModal, setFeedbackModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [Id, setId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [expertId, setexpertId] = useState("");
  const [modesof, setModeof] = useState('');
  console.log(query, toDate, stoDate, "hello")
  const handleClearFilters = () => {
    setFromDate("");
    settoDate("");
    setToDate("")
    setYear("");
    setMonth("");
    setSelectedStatus("");
    setPaymentStatus("");
    setQuery({
      ...query,
      month: "",
      year: "",
      formData: "",
      toDate: "",
      state: "",
      city: "",
      appointmentNumber: ""
    });
  };
  useEffect(() => {
    soundRef.current = new Howl({
      src: [soundSrc],
    });

    // Cleanup on component unmount
    return () => {
      soundRef.current.unload();
    };
  }, [soundSrc]);

  const handleCityChange = (value) => {
    setQuery({ ...query, city: value });
    console.log(value, "value-----");
  };

  const sortedStateList = stateList.slice().sort((a, b) => {

    const stateA = a.state.toUpperCase();
    const stateB = b.state.toUpperCase();

    if (stateA < stateB) {
      return -1;
    }
    if (stateA > stateB) {
      return 1;
    }
    return 0;
  });

  const handleStateChange = (e) => {
    let stateName = e.target.value;
    let selectedState = stateList.find((state) => state.state === stateName);
    if (selectedState) {
      let sortedCities = selectedState.city.slice().sort((a, b) => {
        const cityA = a.toUpperCase();
        const cityB = b.toUpperCase();

        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }
        return 0;
      });
      setCity(sortedCities);
      setQuery({ ...query, state: stateName });
      console.log("state", stateName);
    }
  };


  const handleApplyDate = (e) => {
    setQuery({ ...query, fromDate: fromDate, toDate: stoDate });
  };

  const handleEdit = (id, status) => {
    if (status === "completed") {
      dispatch(updateCallStatus(id, { callInprogress: false }));
    }
    let updateStatus = dispatch(updateAppointment(id, { status: status }));
    updateStatus.then((res) => {
      if (res.status) {
        dispatch(getItemList(query));
      }
    });
  };

  useEffect(() => {
    setQuery({ ...query, paymentStatus, status: selectedStatus, year: year, month: month });
  }, [paymentStatus, selectedStatus, month, year]);

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);
    if (toDate && selectedFromDate > toDate) {
      setToDate('');
    }
  };
  const handleToDateChange = (e) => {
    const selectedToDate = e.target.value;
    setToDate(selectedToDate);
    settoDate(selectedToDate); // Update the stoDate state
  };




  const handleReject = (id, status) => {
    if (status === "reject") {
      dispatch(updateCallStatus(id, { callInprogress: false }));
    }
    let updateStatus = dispatch(rejectAppointment(id, { status: status }));
    updateStatus.then((res) => {
      if (res.status) {
        dispatch(getItemList(query));
      }
    });
  };

  const [showPopUp, setShowPopUp] = useState(false)

  const [idCancel, setIdCancel] = useState('')
  const [statusCancel, setStatusCancel] = useState('')


  const handleCancel = (id, status) => {
    setStatusCancel(status)
    setIdCancel(id)
    setShowPopUp(true)
  };


  const handleCancelPopUp = () => {
    console.log('Appointment cancelled');
    setShowPopUp(false);
  };



  const handleCancelAppointment = (id, status) => {
    // alert(id)
    // alert(status)
    console.log('id and status is here', id, status)
    if (status == "cancelled") {
      dispatch(updateCallStatus(id, { callInprogress: false }));
    }

    // alert(showPopUp)
    let updateStatus = dispatch(cancleAppointment(id, { status: status }));
    updateStatus.then((res) => {
      if (res && res.status) {
        dispatch(getItemList(query));
      }
    });

    setShowPopUp(false)
  };


  const fetchData = () => {
    dispatch(getItemListWithLoader(query));
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, query]);


  const HandlejoinMetting = (id) => {
    alert("ringing is pause........");

    if (soundRef.current) {
      soundRef.current.stop();
    }
    // dispatch(sendNotificationObj({ appointmentId: id }));
    dispatch(updateCallStatus(id, { callInprogress: true }));
    dispatch(VideoCallToken({ channelName: id, uid: uid })).then((res) => {
      console.log("VideoCallToken", VideoCallToken);
      const { tokenA, status } = res;
      if (status) {
        setRtcProps({ ...rtcProps, channel: id });
        setVideoCall(true);
      }
    });
  };

  const HandlestartMetting = (id) => {
    if (soundRef.current) {
      soundRef.current.play();
    }   
    alert("ringing is start........");
    dispatch(updateCallStatus(id, { callInprogress: true }));
    dispatch(VideoCallToken({ channelName: id, uid: uid })).then((res) => {
      console.log("VideoCallToken", VideoCallToken);
      const { tokenA, status } = res;
      if (status) {
        setRtcProps({ ...rtcProps, channel: id });
        setVideoCall(true);
      }
    });
  };


  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };
  const handleCall = (e) => {
    e.preventDefault();
  };
  const handleComplent = (id) => {
    setAppointmentId(id);
    setRaiseComplent(true);

  };


  const handleSchedileappoinment = (row, _id) => {
    setDoctorId(row.doctor._id);
    setId(row._id)
    setModeof(row.mode)
    setRecheduleModal(true);
  };
  const handleFeedback = (row, _id) => {
    setexpertId(row.expert._id);
    setAppointmentId(row._id);
    console.log(row._id, "this is my iid");
    setId(row._id)
    setFeedbackModal(true);
  };

  const downloadPrescription = (id) => {
    dispatch(prescriptionAppointmentId(id)).then((res) => {
      if (res && res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "prescription.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        alert("Prescription is not ready yet.");
      }
    }).catch((error) => {
      console.error('Error during prescription download:', error);
      alert("Prescription is not ready yet.");
      dispatch(isLoading(false))
    });
  };


  const callInProgress = (status) => {
    // alert("soun is calling")
    // console.log("callInProgress", callInProgress);
    // const sound = new Howl({
    //   src: [soundSrc],
    // })
    // sound.pause();
  };

  const callbacks = {
    EndCall: () => {
      setVideoCall(false)
      dispatch(updateCallStatus(rtcProps.channel, { callInprogress: false }));
      dispatch(getItemListWithLoader(query))
    },
  };



  if (videoCall) {
    return (
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card call-video">
                  <AgoraUIKit
                    rtcProps={rtcProps}
                    settings={{
                      mode: 0,
                    }}
                    callbacks={callbacks}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Appointments</h4>
            </div>

            <div
              className="col-sm-8 col-9 text-right m-b-20"
              style={{ textAlign: "end" }}
            >
              {(ROLES.FRANCHISE === role || ROLES.PATIENT === role) && (
                <Link
                  to="/appointment/create"
                  className="btn btn btn-primary btn-rounded float-right"
                >
                  <i className="fa fa-plus"></i> Book Appointment
                </Link>
              )}
              {/* <span>&nbsp;</span> */}
              {/* {
                ROLES.PATIENT == role && (
                  <Link
                    to="/appointment/create/clinic"
                    className="btn btn btn-info btn-rounded float-right"
                  >
                    <i className="fa fa-plus"></i> Clinic appointment
                  </Link>
                )
              } */}
            </div>
          </div>
          {(role === ROLES.FRANCHISE || role === ROLES.DOCTOR || role === ROLES.ADMIN) && (
            <div className="row m-b-20">
              <div className="form-group col-md-3 mt-3">
                <label>From Date</label>
                <input
                  type="date"
                  name="fromDate"
                  className="form-control"
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </div>
              <div className="form-group col-md-3 mt-3">
                <label>To Date</label>
                <input
                  type="date"
                  name="toDate"
                  className="form-control"
                  value={toDate}
                  min={fromDate}
                  onChange={handleToDateChange}
                />
              </div>
              <div className="col-md-1 mt-4">
                <button
                  className="btn btn-sm btn-info"
                  style={{ marginTop: "27px" }}
                  onClick={(e) => handleApplyDate(e)}
                  type="button"
                >
                  Apply
                </button>
              </div>

            </div>
          )}
          {(role === ROLES.ADMIN) && (
            <div className="row m-b-20">
              <div className="col-4">
                <label>Search By Appointment Id</label>
                <input
                  className="form-control"
                  name="appointmentNumber"
                  value={query.appointmentNumber}
                  placeholder="Appointment Id"
                  onChange={(e) => setQuery({ ...query, appointmentNumber: e.target.value })}
                />
              </div>
              <div className="col-4">

                <label>Search By Month</label>
                <select
                  className="form-select"
                  value={month}
                  onChange={handleMonthChange}
                >
                  <option value="">--Select Month--</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="col-4">
                <label>Search By Year</label>
                <select
                  className="form-select"
                  value={year}
                  onChange={handleYearChange}
                >
                  <option value="">--Select Year--</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  {/* Add more years as needed */}
                </select>
              </div>
              <div className="col-lg-4 mt-2">
                <label>State:</label>
                <select
                  name="state"
                  onChange={(e) => handleStateChange(e)}
                  className="form-select">
                  <option value="">Select State</option>
                  {sortedStateList &&
                    sortedStateList.map((itm, index) => (
                      <option value={itm.state} key={index}>
                        {itm.state}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-4 mt-2">
                <label>City:</label>
                <select
                  name="city"
                  onChange={(e) => handleCityChange(e.target.value)}
                  className="form-select">
                  <option value="">Select City</option>
                  {city &&
                    city.map((cit, index) => (
                      <option value={cit} key={index}>
                        {cit}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-4">
                <label>Search By Staus</label>
                <select
                  className="form-select"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">--Select Status--</option>
                  <option value="">All</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="completed">Completed</option>
                  <option value="rejected">Rejected</option>
                  <option value="accepted">Accepted</option>
                  <option value="reschedule">Reschedule</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="refunded">Refunded</option>
                </select>
              </div>
              <div className="col-4">
                <label>Search By Payment Staus</label>
                <select
                  className="form-select"
                  value={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                >
                  <option value="">--Select Status--</option>
                  <option value="">All</option>
                  <option value="Paid">Paid</option>
                  <option value="Unpaid">Unpaid</option>
                  <option value="refunded">Refunded</option>

                </select>
              </div>
              <div className="col-4">
                <button
                  className="btn btn-sm btn-secondary"
                  style={{ marginTop: "27px", marginRight: "10px" }}
                  onClick={handleClearFilters}
                  type="button"
                >
                  Clear Filters
                </button>
              </div>
            </div>
          )}
          {
            role === ROLES.ADMIN && (
              <button className="btn btn-sm btn-secondary mb-1 my-2" onClick={handlePrint}>
                Print
              </button>
            )
          }

          {printPage && <DownloadAppointmentData ItemList={ItemList} />}

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        handleEdit,
                        handleReject,
                        HandlejoinMetting,
                        HandlestartMetting,
                        handleCall,
                        downloadPrescription,
                        handleCancel,
                        handleComplent,
                        handleSchedileappoinment,
                        handleFeedback,
                        callInProgress,
                        dispatch,
                        openModal: handleOpenAppointmentModal,
                        openAppoinmentModal: handleOpenDetailsModal,
                        pageNo: query.page - 1,
                        size: query.size,
                      })}
                      dataTable={ItemList}
                      totalRecord={ItemTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                    {showPopUp && (
                      <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 9999,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        <div style={{
                          backgroundColor: 'white',
                          padding: '20px',
                          borderRadius: '5px',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                          width: '300px',
                          textAlign: 'center',
                        }}>
                          <p>Are you sure you want to cancel this appointment?</p>
                          <div>
                            <button
                              style={{
                                marginRight: '10px',
                                backgroundColor: '#dc3545', // red
                                color: 'white',
                                border: 'none',
                                padding: '5px 10px',
                                borderRadius: '3px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleCancelAppointment(idCancel, statusCancel)}
                            >
                              Yes
                            </button>
                            <button
                              style={{
                                backgroundColor: '#6c757d',
                                color: 'white',
                                border: 'none',
                                padding: '5px 10px',
                                borderRadius: '3px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleCancelPopUp()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RaiseComplent
        handleClose={(value) => setRaiseComplent(value)}
        show={raiseComplent}
        appointmentId={appointmentId}
      />

      <RecheduleAppoinment
        show={RecheduleModal}
        doctorId={doctorId}
        mymodes={modesof}
        Id={Id}
        handleClose={(value) => setRecheduleModal(value)}
      />

      <FeedbackComplete
        show={FeedbackModal}
        expertId={expertId}
        appointmentId={appointmentId}
        handleClose={(value) => setFeedbackModal(value)}
      />
      <AppointmentModal
        show={showAppointmentModal}
        handleClose={handleCloseAppointmentModal}
        appointment={selectedAppointment}
      />
    </>
  );
}

export default Appointment;
import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({ handleDelete, pageNo = 0, size = 10 }) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "subject",
      text: "Subject",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "_id",
      text: "Action",
      hidden: role === ROLES.PATIENT,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => handleDelete(cell)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];
};

export default TableColumn;

import {
    invoice
} from "./service";
import {
    SET_INVOICE_LIST,
    SET_INVOICE_LIST_TOTAL_PAGE,
    isLoading,
} from "../../../constants/actionConstants";
import {
    toast
} from "react-toastify";


export const getinvoiceList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getInvoiceList = await invoice.getinvoiceList(params);
    console.log(getInvoiceList, "getInvoiceList----");
    dispatch({
        type: SET_INVOICE_LIST,
        payload: getInvoiceList.responseData,
    });
    dispatch({
        type: SET_INVOICE_LIST_TOTAL_PAGE,
        payload: getInvoiceList.totalDocuments,
    });

    dispatch(isLoading(false));
};


export const getInvoiceById = (id) => async (dispatch) => {
    dispatch(isLoading(true))
    let Pres = await invoice.getInvoiceById(id)
    dispatch(isLoading(false))
    return Pres
}
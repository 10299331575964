import api from "../../../dependencies/utils/Api";

import { SERVICE_REQUEST } from "../../../constants/ApplicationUrl";

export const ServiceRequestService = {
   
    // getItemList(params) {
    //     return api.GET(`${SERVICE_REQUEST}?page=${params.page}`).then((response) => {
    //         const { data: {message, status, data, totalRecords,totalPages} = {}} = response    
    //         if(status) {
    //             return {message, data, totalPages, totalRecords}
    //         }else {

    //         }
    //     })
    // },


    getItemList(params) {
        return api.GET(`${SERVICE_REQUEST}?page=${params.page}&name=${params.name ||''}&mobile=${params.mobile||''}&status=${params.status ||''}&paymentStatus=${params.paymentStatus ||''}&month=${params.month||''}&year=${params.year||''}&fromDate=${params.fromDate ||''}&toDate=${params.toDate||''}&state=${params.state||''}&city=${params.city||''}&pinCode=${params.pinCode||''}&search=${params.search||''}`).then((response) => {
            if (response) {
                const { data: { message, status, data, totalRecords, totalPages } = {} } = response;
                if (status) {
                    return { message, data, totalPages, totalRecords };
                } else {
                    // Handle case where status is false
                }
            } else {
                // Handle case where response is undefined
                return { message: 'No response from server', status: false };
            }
        }).catch(error => {
            // Handle any errors that occur during the request
            console.error('Error in getItemList:', error);
            return { message: 'Error: Request failed', status: false };
        });
    },    
    
    updateStatus(id,params) {
        return api.PUT(`${SERVICE_REQUEST}/${id}`,params).then((response) => {
            const { data: {message, status} = {}} = response    
            if(status) {
                return {message, status}
            }else {

            }
        })
    },
    createItem(params) {
        return api.POST(SERVICE_REQUEST, params).then((response) => {
            const { data: {message, status, data} = {}} = response    
            if(status) {
                return {message, data, status}
            }else {

            }
        })
    }

}
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
 import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateDoctor } from "../dependiencies/action"

function AddExperince(props) {
  const { handleClose, show } = props;
  const { user } = useSelector((state) => state.login);
  // const [form, setForm] = useState({});
 
  const handleInputChange = (name, value) => {
    setExperience({ ...experience, [name]: value });
  };
  const [experience, setExperience] = useState([
    {
      jobTitle: '',
      companyName: '',
      cityState: '',
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      jobDescription: ''
    }
  ]);


  const dispatch = useDispatch();
  // const validator = () => {
  //   let errors = {};
  //   if (!form.amount) {
  //     errors = { ...errors, amount: "This field is required!" };
  //   }
  //   else if (form.amount <= 1000) {
  //     errors = { ...errors, amount: "Amount must be greater than 1000!" };
  //   }
  //   setError(errors);
  //   return errors;
  // };



  // const handleSave = (e) => {
  //   // e.preventDefault();
  //   // let vallidate = validator();
  //   // if (!isEmpty(vallidate)) {
  //   //   return false;
  //   // }
  //   // dispatch(withdraw(form)).then(() => {
  //   //   handleClose(false)
  //   //   setForm({})
  //   // })
  // };
  const handleSave = (e) => {
    e.preventDefault();
    // let validate = validator();
    // if (!isEmpty(validate)) {
    //   return false
    // }
    dispatch(updateDoctor({
      experience: {
        JobTitle: experience.JobTitle,
        companyName: experience.companyName,
        cityState: experience.cityState,
        startMonth: experience.startMonth,
        startYear: experience.startYear,
        endMonth: experience.endMonth,
        endYear: experience.endYear,
        JobDescription: experience.JobDescription,
      }
    })).then(() => {
      setExperience({
        JobTitle: "",
        companyName: "",
        cityState: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        JobDescription: "",
      });
      handleClose(false)
    }).catch((error) => {
      console.error("Error updating doctor:", error);
    });
  };

  const months = [
    { name: 'Month', value: 'month' },
    { name: 'January', value: 'january' },
    { name: 'February', value: 'february' },
    { name: 'March', value: 'march' },
    { name: 'April', value: 'april' },
    { name: 'May', value: 'may' },
    { name: 'June', value: 'june' },
    { name: 'July', value: 'july' },
    { name: 'August', value: 'august' },
    { name: 'September', value: 'september' },
    { name: 'October', value: 'october' },
    { name: 'November', value: 'november' },
    { name: 'December', value: 'december' }
  ];

  const startmonths = [
    { name: 'Month', value: 'month' },
    { name: 'January', value: 'january' },
    { name: 'February', value: 'february' },
    { name: 'March', value: 'march' },
    { name: 'April', value: 'april' },
    { name: 'May', value: 'may' },
    { name: 'June', value: 'june' },
    { name: 'July', value: 'july' },
    { name: 'August', value: 'august' },
    { name: 'September', value: 'september' },
    { name: 'October', value: 'october' },
    { name: 'November', value: 'november' },
    { name: 'December', value: 'december' }
  ];

  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = 1970; year <= currentYear; year++) {
    years.push({ value: year, label: year });
  }

  const startYear = new Date().getFullYear();
  const sYears = Array.from({ length: startYear - 1970 + 1 }, (v, i) => {
    const sYears = 1970 + i;
    return { value: sYears.toString(), name: sYears.toString() };
  });

  const countries = [
    { name: "Afghanistan" },
    { name: "Albania" },
    { name: "Algeria" },
    { name: "Andorra" },
    { name: "Angola" },
    { name: "Antigua and Barbuda" },
    { name: "Argentina" },
    { name: "Armenia" },
    { name: "Australia" },
    { name: "Austria" },
    { name: "Azerbaijan" },
    { name: "Bahamas" },
    { name: "Bahrain" },
    { name: "Bangladesh" },
    { name: "Barbados" },
    { name: "Belarus" },
    { name: "Belgium" },
    { name: "Belize" },
    { name: "Benin" },
    { name: "Bhutan" },
    { name: "Bolivia" },
    { name: "Bosnia and Herzegovina" },
    { name: "Botswana" },
    { name: "Brazil" },
    { name: "Brunei" },
    { name: "Bulgaria" },
    { name: "Burkina Faso" },
    { name: "Burundi" },
    { name: "Cabo Verde" },
    { name: "Cambodia" },
    { name: "Cameroon" },
    { name: "Canada" },
    { name: "Central African Republic" },
    { name: "Chad" },
    { name: "Chile" },
    { name: "China" },
    { name: "Colombia" },
    { name: "Comoros" },
    { name: "Congo, Democratic Republic of the" },
    { name: "Congo, Republic of the" },
    { name: "Costa Rica" },
    { name: "Croatia" },
    { name: "Cuba" },
    { name: "Cyprus" },
    { name: "Czech Republic" },
    { name: "Denmark" },
    { name: "Djibouti" },
    { name: "Dominica" },
    { name: "Dominican Republic" },
    { name: "Ecuador" },
    { name: "Egypt" },
    { name: "El Salvador" },
    { name: "Equatorial Guinea" },
    { name: "Eritrea" },
    { name: "Estonia" },
    { name: "Eswatini" },
    { name: "Ethiopia" },
    { name: "Fiji" },
    { name: "Finland" },
    { name: "France" },
    { name: "Gabon" },
    { name: "Gambia" },
    { name: "Georgia" },
    { name: "Germany" },
    { name: "Ghana" },
    { name: "Greece" },
    { name: "Grenada" },
    { name: "Guatemala" },
    { name: "Guinea" },
    { name: "Guinea-Bissau" },
    { name: "Guyana" },
    { name: "Haiti" },
    { name: "Honduras" },
    { name: "Hungary" },
    { name: "Iceland" },
    { name: "India" },
    { name: "Indonesia" },
    { name: "Iran" },
    { name: "Iraq" },
    { name: "Ireland" },
    { name: "Israel" },
    { name: "Italy" },
    { name: "Jamaica" },
    { name: "Japan" },
    { name: "Jordan" },
    { name: "Kazakhstan" },
    { name: "Kenya" },
    { name: "Kiribati" },
    { name: "Kuwait" },
    { name: "Kyrgyzstan" },
    { name: "Laos" },
    { name: "Latvia" },
    { name: "Lebanon" },
    { name: "Lesotho" },
    { name: "Liberia" },
    { name: "Libya" },
    { name: "Liechtenstein" },
    { name: "Lithuania" },
    { name: "Luxembourg" },
    { name: "Madagascar" },
    { name: "Malawi" },
    { name: "Malaysia" },
    { name: "Maldives" },
    { name: "Mali" },
    { name: "Malta" },
    { name: "Marshall Islands" },
    { name: "Mauritania" },
    { name: "Mauritius" },
    { name: "Mexico" },
    { name: "Micronesia" },
    { name: "Moldova" },
    { name: "Monaco" },
    { name: "Mongolia" },
    { name: "Montenegro" },
    { name: "Morocco" },
    { name: "Mozambique" },
    { name: "Myanmar" },
    { name: "Namibia" },
    { name: "Nauru" },
    { name: "Nepal" },
    { name: "Netherlands" },
    { name: "New Zealand" },
    { name: "Nicaragua" },
    { name: "Niger" },
    { name: "Nigeria" },
    { name: "North Korea" },
    { name: "North Macedonia" },
    { name: "Norway" },
    { name: "Oman" },
    { name: "Pakistan" },
    { name: "Palau" },
    { name: "Palestine" },
    { name: "Panama" },
    { name: "Papua New Guinea" },
    { name: "Paraguay" },
    { name: "Peru" },
    { name: "Philippines" },
    { name: "Poland" },
    { name: "Portugal" },
    { name: "Qatar" },
    { name: "Romania" },
    { name: "Russia" },
    { name: "Rwanda" },
    { name: "Saint Kitts and Nevis" },
    { name: "Saint Lucia" },
    { name: "Saint Vincent and the Grenadines" },
    { name: "Samoa" },
    { name: "San Marino" },
    { name: "Sao Tome and Principe" },
    { name: "Saudi Arabia" },
    { name: "Senegal" },
    { name: "Serbia" },
    { name: "Seychelles" },
    { name: "Sierra Leone" },
    { name: "Singapore" },
    { name: "Slovakia" },
    { name: "Slovenia" },
    { name: "Solomon Islands" },
    { name: "Somalia" },
    { name: "South Africa" },
    { name: "South Korea" },
    { name: "South Sudan" },
    { name: "Spain" },
    { name: "Sri Lanka" },
    { name: "Sudan" },
    { name: "Suriname" },
    { name: "Sweden" },
    { name: "Switzerland" },
    { name: "Syria" },
    { name: "Taiwan" },
    { name: "Tajikistan" },
    { name: "Tanzania" },
    { name: "Thailand" },
    { name: "Timor-Leste" },
    { name: "Togo" },
    { name: "Tonga" },
    { name: "Trinidad and Tobago" },
    { name: "Tunisia" },
    { name: "Turkey" },
    { name: "Turkmenistan" },
    { name: "Tuvalu" },
    { name: "Uganda" },
    { name: "Ukraine" },
    { name: "United Arab Emirates" },
    { name: "United Kingdom" },
    { name: "United States" },
    { name: "Uruguay" },
    { name: "Uzbekistan" },
    { name: "Vanuatu" },
    { name: "Vatican City" },
    { name: "Venezuela" },
    { name: "Vietnam" },
    { name: "Yemen" },
    { name: "Zambia" },
    { name: "Zimbabwe" }
  ];
  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={(e) => handleClose(false)}
        backdrop="static"
      >
        <Modal.Header>
          <span>Experince</span>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="">
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="focus-label">Job Title</label>
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      value={experience.JobTitle}
                      name="JobTitle"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="focus-label">Company Name</label>
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      value={experience.companyName}
                      name="companyName"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="focus-label">Country Name</label>
                  <div className="form-group form-focus">
                    <select
                      className="form-control floating"
                      value={experience.countryName}
                      name="countryName"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    >
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-6 mt-3">
                  <label className="focus-label">City State</label>
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      value={experience.cityState}
                      name="cityState"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="focus-label">start Date</label>
                  <div className="row">
                    <div className="col-md-6">
                      <select
                        className="form-control floating"
                        value={experience.startMonth}
                        name="startMonth"
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      >
                        {startmonths.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <select
                        className="form-control floating"
                        value={experience.startYear}
                        name="startYear"
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      >
                        <option value="" disabled>Select Year</option>
                        {sYears.map((year) => (
                          <option key={year.value} value={year.value}>
                            {year.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="focus-label">End Date</label>
                  <div className="row">
                    <div className="col-md-6">
                      <select
                        className="form-control floating"
                        value={experience.endMonth}
                        name="endMonth"
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      >
                        {months.map((month, index) => (
                          <option key={index} value={month.value}>
                            {month.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <select
                        className="form-control floating"
                        value={experience.endYear}
                        name="endYear"
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      >
                        {years.map(year => (
                          <option key={year.value} value={year.value}>
                            {year.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="focus-label">Description</label>
                  <div className="form-group form-focus">
                    <textarea
                      type="text"
                      className="form-control floating"
                      value={experience.JobDescription}
                      name="JobDescription"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleClose(false)}>
            Close
          </button>
          <button
            className="btn btn-sm btn-success"
            onClick={(e) => handleSave(e)}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddExperince;

import { PASSWORD_CHANGE, UPDATE_DOCTOR, UPDATE_DOCTOR_SLOT, UPDATE_DOCTOR_DETAIL } from "../../../constants/ApplicationUrl";
import api from "../../../dependencies/utils/Api";

export const ProfileService = {

  updateProfile(params) {
    return api.PUTDATA(UPDATE_DOCTOR, params).then((response) => {
      const { data: { data, status, message } = {} } = response;
      return { data, status, message }
    });
  },


  updateDoctor(params) {
    return api.PUTDATA(UPDATE_DOCTOR_DETAIL, params).then((response) => {
      console.log("response", response);
      const { data: { message, data } = {}, status } = response
      if (status) {
        return { message, status, data };
      }
      else {
        return { message, status };
      }
    });
  },


  updateSlot(params) {
    return api.PUT(UPDATE_DOCTOR_SLOT, params).then((response) => {
      const { data: { status, message, data } = {} } = response;
      return { status, message, data }
    });

  },

  passwordChange(params) {
    return api.PUT(PASSWORD_CHANGE, params).then((response) => {
      const { data: { status, message } = {} } = response;
      return { status, message }
    });
  }
};

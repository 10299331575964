import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { addFeedback } from "../dependiencies/action";
import { isEmpty } from "lodash";
// import { getappoinmenttimeslot } from "../dependiencies/action";




function FeedbackComplete(props) {
  const { handleClose, show, expertId, appointmentId } = props;
  const [form, setForm] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (expertId) {
      setForm({ expertId: expertId });
    }
  }, [expertId]);
  useEffect(() => {
    if (appointmentId) {
      setForm({ appointmentId: appointmentId });
    }
  }, [appointmentId]);



  const dispatch = useDispatch();

  //slect date from here it will be  good   GetTimeSlot
  const handleInputChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const validator = () => {
    let errors = {};
    if (!form.subject) {
      errors = { ...errors, subject: "This field is required!" };
    }
    if (!form.description) {
      errors = { ...errors, description: "This field is required!" };
    }
    setError(errors);

    return errors;
  };


  const handleFeedback = (e) => {

    e.preventDefault();
    let vallidate = validator();

    if (!isEmpty(vallidate)) {
      return false;
    }
    dispatch(addFeedback(form, appointmentId)).then(() => {
      handleClose(false)
      setForm({})
    })
  };
  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} backdrop="static">
        <Modal.Header>
          <span>Feedback</span>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Subject</label>
              <input
                type="text"
                name="subject"
                value={form.subject}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                placeholder="subject"
                className="form-control"
              />

              {error && error.subject && (
                <span className="text-danger">{error.subject}</span>
              )}
            </div>
            <div className="form-group">
              <label>Discription</label>
              <textarea
                type="text"
                name="description"
                value={form.description}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                placeholder="description"
                className="form-control"
              ></textarea>
              {error && error.description && (
                <span className="text-danger">{error.description}</span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleClose(false)}
          >
            Close
          </button>
          <button
            className="btn btn-sm btn-success"
            onClick={(e) => handleFeedback(e)}
          >
            Feedback
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FeedbackComplete;

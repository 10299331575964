import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import TableColumn from "./dependencies/TableColumn";
import axios from "axios";
import Api from "../../dependencies/utils/Api";
import { Transaction_Tracking } from "../../constants/ApplicationUrl";
function Transactions() {
  const [query, setQuery] = useState({
     page: 1,
     size: 10,
     name:'',
     mobile:'',
     email:'',
     status:'',
     fromDate:'',
     toDate:'',
    orderId:''

     });
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecord,setTotalRecord]=useState(0)
  const [balance,setBalance]=useState(0)
  console.log(totalPages,"toahh")
  useEffect(() => {
    fetchTransactions();
  }, [query]); 

  const fetchTransactions = async () => {
    try {
      const response = await Api.GET(`${Transaction_Tracking}?page=${query.page}&limit=${query.size}&name=${query.name}&email=${query.email}&status=${query.status}&mobile=${query.mobile}&fromDate=${query.fromDate}&toDate=${query.toDate}&orderId=${query.orderId}`);
      console.log(response.data.totalPages,"response")
      setTransactions(response.data.transactionDetail);
      setTotalPages(response.data.totalPages);
      setTotalRecord(response.data.totalTransactions)
      setBalance(response.data.walletBalance)
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleClearFilters=()=>{
    setQuery({
      page:1,
      size:10,
      name:'',
     mobile:'',
     email:'',
     status:'',
     fromDate:'',
     toDate:'',
     orderId:''
    })
  }

  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
        <div className="col-sm-4 col-3">
              <h4 className="page-title">Transactions</h4>
            </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
                      <div className="row m-b-20">
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Name</label>

              <input
                className="form-control"
                name="name"
                placeholder="Name"
                value={query.name}
                onChange={(e) => setQuery({ ...query, name: e.target.value })}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Mobile</label>
              <input
                className="form-control"
                name="mobile"
                value={query.mobile}
                placeholder="Mobile"
                onChange={(e) => setQuery({ ...query, mobile: e.target.value })}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Email</label>
              <input
                className="form-control"
                name="email"
                value={query.email}
                placeholder="Email"
                onChange={(e) => setQuery({ ...query, email: e.target.value })}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Order Id</label>
              <input
                className="form-control"
                name="order_id"
                value={query.orderId}
                placeholder="Order Id"
                onChange={(e) => setQuery({ ...query, orderId: e.target.value })}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>From Date</label>
              <input
                type="date"
                name="fromDate"
                className="form-control"
                value={query.fromDate}
                onChange={(e) => setQuery({ ...query, fromDate: e.target.value })}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>To Date</label>
              <input
                type="date"
                name="toDate"
                className="form-control"
                value={query.toDate}
                min={query.fromDate}
                onChange={(e) => setQuery({ ...query, toDate: e.target.value })}
              />
            </div>
            {/* <div className="row m-b-20"> */}

            <div className="form-group col-md-3 mt-3">
              <label>Search By Staus</label>
              <select
                className="form-select"
                value={query.status}
                onChange={(e) => setQuery({ ...query, status: e.target.value })}
              >
                <option value="">--Select Status--</option>
                <option value="">All</option>
                <option value="Success">Success</option>
              <option value="Failure">Failure</option>
              <option value="Timeout">Timeout</option>
              </select>
            </div>

            <div className="col-4">
              <button
                className="btn btn-sm btn-secondary"
                style={{ marginTop: "27px", marginRight: "10px" }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear Filters
              </button>
            </div>



            {/* </div> */}
          </div>

            <div
              className="col-sm-8 col-9 text-right m-b-20"
              style={{ textAlign: "end" }}
            >
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 pr-0 bg-white m-1">
              <div className="dash-widget d-flex">
                <span className="dash-widget-bg2">
                  <i class="fas fa-rupee-sign"></i>
                </span>
                <div
                  className="dash-widget-info float-left pl-2 "
                  style={{ marginLeft: "5px" }}
                >
                  <p>Total Earning</p>
                  <h4>Rs. {balance}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        pageNo: query.page - 1,
                        size: query.size,
                      })}   
                      dataTable={transactions}
                      totalRecord={totalRecord}
                      onPageChange={handlePageChange}
                      onTableChange={() => {}}
                      keyField="_id"
                      sizePerPage={query.size}
                      // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transactions;

import React, { useEffect, useState } from "react";
import { storage } from "../../dependencies/store/storage";
import { ROLES } from "../../constants/role";
import { Link } from "react-router-dom";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import TableColumn from "./dependiencies/TableColumn";
import { getItemList, updateComplent } from "./dependiencies/action";
import Answer from "./Update";
import { toDate } from "date-fns";

function Complaint(props) {
  const { ItemList, ItemTotalPage, ComplaintTotalRecord } = useSelector(
    (state) => state.complaint
  );
  console.log("ItemList----------", ItemList);
  const role = storage.getUserRole();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
    name: "",
    email: "",
    mobile: "",
    fromDate: "",
    toDate: "",
    status: ""
  });
  const [rowData, setRowData] = useState({});
  const [showAnswer, setShowAnswer] = useState(false);
  const handleAnswer = (row) => {
    setRowData(row);
    setShowAnswer(true);
  };
  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  useEffect(() => {
    dispatch(getItemList(query));
  }, [dispatch, query]);

  const handleUpdate = (id, data) => {
    dispatch(updateComplent(id, data)).then((res) => {
      if (res.status) {
        setRowData({});
        setShowAnswer(false);
        dispatch(getItemList(query));
      }
    });
  };
  const handleStatus = (id, status) => {
    dispatch(updateComplent(id, { status: status })).then((res) => {
      if (res.status) {
        setRowData({});
        setShowAnswer(false);
        dispatch(getItemList(query));
      }
    });
  };
  const handleSearch = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };
  const handleClearFilters = () => {
    setQuery({
      ...query,
      name: "",
      mobile: "",
      email: "",
      fromDate: "",
      toDate: "",
      status: "",

    });
  };
  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-sm-4 col-3">
              {role === ROLES.PATIENT ? (
                <h4 className="page-title">Tickets</h4>
              ) : (
                <h4 className="page-title">Complaint List</h4>
              )}
            </div>
            <div
              className="col-sm-6 col-6 text-right m-b-20"
              style={{ textAlign: "end" }}
            >

            </div>
          </div>
          <div className="row m-b-20">
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Name</label>

              <input
                className="form-control"
                name="name"
                placeholder="Name"
                value={query.name}
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Mobile</label>
              <input
                className="form-control"
                name="mobile"
                value={query.mobile}
                placeholder="Mobile"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Email</label>
              <input
                className="form-control"
                name="email"
                value={query.email}
                placeholder="Email"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>From Date</label>
              <input
                type="date"
                name="fromDate"
                className="form-control"
                value={query.fromDate}
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>To Date</label>
              <input
                type="date"
                name="toDate"
                className="form-control"
                value={query.toDate}
                min={query.fromDate}
                onChange={handleSearch}
              />
            </div>
            {/* <div className="row m-b-20"> */}

            <div className="form-group col-md-3 mt-3">
              <label>Search By Staus</label>
              <select
                className="form-select"
                value={query.status}
                onChange={(e) => setQuery({ ...query, status: e.target.value })}
              >
                <option value="">--Select Status--</option>
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="follow">Follow</option>
                <option value="resolved">Resolved</option>
              </select>
            </div>

            <div className="col-4">
              <button
                className="btn btn-sm btn-secondary"
                style={{ marginTop: "27px", marginRight: "10px" }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear Filters
              </button>
            </div>



            {/* </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        handleAnswer,
                        pageNo: query.page - 1,
                        size: query.size,
                        handleUpdate,
                        handleStatus
                      })}
                      dataTable={ItemList}
                      totalRecord={ItemTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Answer
        show={showAnswer}
        rowData={rowData}
        handleClose={(value) => setShowAnswer(value)}
        handleAdd={(id, data) => handleUpdate(id, data)}
      />
    </>
  );
}

export default Complaint;

import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import Api from '../../dependencies/utils/Api';
import { useDispatch, useSelector } from "react-redux";
import { getReferalTransactionList } from "./dependencies/action";
import moment from 'moment';
const ReferalTrasactionModal = ({ show, handleClose,userId,usedRefferId }) => {
    const [query, setQuery] = useState({ userId: userId ||'',usedRefferId: usedRefferId||'', page: 1, size: 10, fromDate: '', toDate: '' });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { TransactionList, TrasactionTotalPage,TotalIncome } = useSelector((state) => state.referalTransaction);

    useEffect(() => {
        if (show) {
            setLoading(true);
            dispatch(getReferalTransactionList(query))
                .finally(() => setLoading(false));
        }
    }, [dispatch, show, query]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= TrasactionTotalPage) {
            setQuery({ ...query, page: newPage });
        }
    };

    const handleModalClose = () => {
        handleClose(); 
    };
    const clearFilter=()=>{
      setQuery({
        page:1,
        size:10,
        fromDate:'',
        toDate:'',
        userId:userId
      })
    }
    return (
        <>
            <Modal size="xl" show={show} onHide={handleModalClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Refferal Income Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row m-b-20">
       
          <div className="col-4">
            <label>From Date</label>
            <input
              type="date"
              name="fromDate"
              value={query.fromDate}
              className="form-control"
              onChange={(e) => setQuery(prevQuery => ({
                ...prevQuery,
                fromDate: e.target.value,
              }))}
            />
          </div>
          <div className="col-4">
            <label>To Date</label>
            <input
              type="date"
              name="toDate"
              value={query.toDate}
              min={query.fromDate}
              className="form-control"
              onChange={(e) => setQuery(prevQuery => ({
                ...prevQuery,
                toDate: e.target.value,
              }))}
            />
          </div>
          <div className="col-5">
            <button className="btn btn-danger ml-2" onClick={clearFilter}>
              Clear Filter
            </button>
          </div>
        </div>
                    <div className="col-md-12">
                        <br />
                        <table className="table table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">Loading...</td>
                                    </tr>
                                ) : TransactionList.length > 0 ? (
                                    TransactionList.map((transaction, index) => (
                                        <tr key={transaction._id}>
                                            <td>{(query.page - 1) * query.size + index + 1}</td>
                                            <td>{moment(transaction.createdAt).format("DD/MM/YYYY h:mm:ss a")}</td>
                                            <td>{transaction.usedRefferId.name}</td>
                                            <td>{transaction.usedRefferId.email}</td>
                                            <td>{transaction.amount}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">No transactions found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination justify-content-center mt-4">
                        <button
                            className="btn btn-primary mr-2"
                            onClick={() => handlePageChange(query.page - 1)}
                            disabled={query.page === 1}
                        >
                            Previous
                        </button>
                        <span className="px-3 mt-1">
                            Page {query.page} of {TrasactionTotalPage}
                        </span>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => handlePageChange(query.page + 1)}
                            disabled={query.page === TrasactionTotalPage}
                        >
                            Next
                        </button>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-danger" onClick={handleModalClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReferalTrasactionModal;

import api from "../../../dependencies/utils/Api";

import { SUPPORT, CREATE_COMPLENT } from "../../../constants/ApplicationUrl";

export const ServiceRequestService = {

    getItemList(params) {
        return api.GET(`${CREATE_COMPLENT}?page=${params.page}&size=${params.size}`).then((response) => {
            console.log(response, "res support ka")
            if (!response || !response.data || typeof response.data !== 'object') {
                return { message: '', status: false, data: [] };
            }
            const { data: { message, status, raiseComplaints, totalData, totalPages } = {} } = response;
            return { message, status, raiseComplaints, totalData, totalPages };
        });
    },

    createComplaint(params) {
        return api.POST(`${SUPPORT}`, params).then((response) => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {

            }
        })
    },


}
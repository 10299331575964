import {
    CareerService
} from "./service";
import {
    SET_SERVICE_ORDER_LIST,
    isLoading,
} from "../../../constants/actionConstants";
import {
    toast
} from "react-toastify";

// export const addCareerItem = (params) => async (dispatch) => {
//     dispatch(isLoading(true));
//     let getItemList = await CareerService.addCareerItem(params);

//     dispatch(isLoading(false));

//     return getItemList
// };

export const addCareerItem = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    const getItemList = await CareerService.addCareerItem(params).catch((error) => {
        const { message,status } = error;
        return { status: false, message: "Server Error" };
    });

    if (getItemList) {
        const { message, status } = getItemList;

        if (status) {
            toast.success(message);
            dispatch(isLoading(false));
            return { message, status };
        } else {
            toast.error(message);
        }
    }
    dispatch(isLoading(false));
    return getItemList
};

// import React from 'react';
// import CanvasJSReact from '@canvasjs/react-charts';

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const TotalAppointmentChat = (props) => {
//   const { data: { totalAppointmentMonthly } } = props

//   const getMonthName = (monthValue) => {
//     const monthDate = new Date(2023, monthValue - 1, 1); // Using a specific year (2023) and setting the day to 1
//     const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
//     return monthName;
//   }

//   let dataSet = []
//   if (totalAppointmentMonthly) {
//     dataSet = totalAppointmentMonthly.map((item) => {
//       return { label: getMonthName(item.x), y: item.y }
//     })
//   }

//   const options = {
//     theme: "light2",
//     animationEnabled: true,
//     exportEnabled: false,
//     title: {
//       text: "Number of Appointments"
//     },

//     data: [
//       {
//         type: "area",
//         xValueFormatString: "YYYY",
//         dataPoints: dataSet
//       }
//     ]
//   }


//   return (
//     <div>
//       {
//         totalAppointmentMonthly && (
//           <CanvasJSChart options={options} />
//         )
//       }

//     </div>
//   );
// };

// export default TotalAppointmentChat;




// import React from 'react';
// import CanvasJSReact from '@canvasjs/react-charts';

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const TotalAppointmentChat = (props) => {
//   const { data: { totalAppointmentMonthly } } = props

//   const getMonthName = (monthValue) => {
//     const monthDate = new Date(2023, monthValue - 1, 1); // Using a specific year (2023) and setting the day to 1
//     const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
//     return monthName;
//   }

//   let dataSet = []
//   if (totalAppointmentMonthly) {
//     dataSet = totalAppointmentMonthly.map((item) => {
//       return { label: getMonthName(item.x), y: item.y }
//     })
//   }

//   const options = {
//     theme: "light2",
//     animationEnabled: true,
//     exportEnabled: false,
//     title: {
//       text: "Number of Appointments"
//     },
//     data: [
//       {
//         type: "bar", // Change type to "bar" for bar chart
//         dataPoints: dataSet
//       }
//     ]
//   }

//   return (
//     <div>
//       {
//         totalAppointmentMonthly && (
//           <CanvasJSChart options={options} />
//         )
//       }
//     </div>
//   );
// };

// export default TotalAppointmentChat;



import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const TotalAppointmentChat = (props) => {
  const chartContainer = useRef(null);
  const { data: { totalAppointmentMonthly } } = props;

  useEffect(() => {
    if (totalAppointmentMonthly && chartContainer.current) {
      const chartData = {
        labels: totalAppointmentMonthly.map(item => getMonthName(item.x)),
        datasets: [{
          label: 'Number of Appointments',
          data: totalAppointmentMonthly.map(item => item.y),
          backgroundColor: 'rgba(54, 162, 235, 0.2)', // Background color for the bars
          borderColor: 'rgba(54, 162, 235, 1)', // Border color for the bars
          borderWidth: 1, // Border width for the bars
        }]
      };

      const chartOptions = {
        indexAxis: 'x', // Change the axis to display bars horizontally
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Number of Appointments'
          }
        }
      };

      const barChart = new Chart(chartContainer.current, {
        type: 'bar',
        data: chartData,
        options: chartOptions
      });

      return () => barChart.destroy(); // Cleanup on unmount
    }
  }, [totalAppointmentMonthly]);

  const getMonthName = (monthValue) => {
    const monthDate = new Date(2023, monthValue - 1, 1);
    const monthName = monthDate.toLocaleString('en-US', { month: 'short' });
    return monthName;
  };

  return <canvas ref={chartContainer} />;
};

export default TotalAppointmentChat;

import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import moment from 'moment';

function DownloadServiceData() {
  const { ItemList } = useSelector((state) => state.serviceRequest);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Doctor Data',
  });
useEffect(()=>{ 
handlePrint()
})
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the format as needed
  };
  return (
    <div ref={componentRef} style={{ width: '100%', height: '100%', padding: '20px' }}>
    <div style={{ marginBottom: '20px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Service Request List</h1>
      <Button onClick={handlePrint} variant="primary" style={{ float: 'right' }}>Print</Button>
    </div>
    <table className="table table-striped">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Service Id</th>
            <th>Sevice Name</th>
            <th>Patient Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>State</th>
            <th>City</th>
            <th>Pincode</th>
            <th>Status</th>
            <th>Payment Status</th>
            <th>Request Date</th>
    
          </tr>
        </thead>
        <tbody>
          {ItemList.map((service, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{service.serviceNumber}</td>
              <td>{service.serviceName}</td>
              <td>{service.customerName}</td>
              <td>{service.age}</td>
              <td>{service.gender}</td>
              <td>{service.state}</td>
              <td>{service.city}</td>
              <td>{service.pin_code}</td>
              <td>{service.status}</td>
              <td>{service.paymentStatus}</td>
              <td>{ moment(service.requestDate).format('DD/MM/YYYY') + ' | ' + service.requestTime}</td>
             
            </tr>
          ))}
        </tbody>
      </table>
      {/* <Button onClick={handlePrint}>Print</Button> */}
    </div>
  );
}

export default DownloadServiceData;

import React from "react";
import { Link } from "react-router-dom";
import MainHeader from "../common/MainHeader";
import { useEffect } from "react";
import MainFooter from "../common/MainFooter";
import { useState } from "react";
import "./index.css"

function Privacy() {
  // const { isLogin } = useSelector((state) => state.login);

  // useEffect(() => {
  //   document.title = 'Fever99 - Gallery';
  // }, []);
  const [tech, setTech] = useState(1)


  return (
    <>
      <head>
        <title>Fever99-Gallery</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <MainHeader />
      <main id="main">
        <div className="breadcrumbs">
          <div className="wrapper" style={{ backgroundImage: `url("https://wordpress.themeholy.com/mediax/wp-content/uploads/2023/12/breadcumb-bg.jpg")` }}>
            <div className="page-header d-flex align-items-center">
              <div className="container position-relative">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-6 text-center">
                    <h2>Gallery</h2>
                    <nav>
                      <div className="container">
                        <ol>
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>Image Gallery</li>
                        </ol>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Gallery */}

        <section className='py-5'>
          <div className='container'>
            <div>
              <div className="tabs">
                <button
                  onClick={() => setTech(tech === 0 ? 0 : 0)}
                  className={
                    tech === 0
                      ? "tab"
                      : "active-tabs"}>
                  Image
                </button>
                <button
                  onClick={() => setTech(tech === 1 ? 0 : 1)}
                  className={
                    tech === 1
                      ? "tab"
                      : "active-tabs"}>
                  Video
                </button>
              </div>

              <div className="">
                {tech === 0 ? (
                  <div className="">
                    <div class="row py-5">
                      <div class="col-lg-4">
                        <img src="https://www.fever99.com/static/media/about.014a2dc522629e7deff6.jpg" alt="" className="img-fluid rounded-2 mt-4" />
                      </div>
                      <div class="col-lg-4">
                        <img src="https://media.istockphoto.com/id/1130934892/photo/doctor-doing-social-service-in-village.jpg?s=612x612&w=0&k=20&c=-YbLWRhnhfzvUxl5fQmpjBuUHVV8KfQJ01WUa7znP5o=" alt="" className="img-fluid rounded-2 mt-4" />
                      </div>
                      <div class="col-lg-4">
                        <img src="https://www.fever99.com/static/media/about.014a2dc522629e7deff6.jpg" alt="" className="img-fluid rounded-2 mt-4" />                      </div>
                      <div class="col-lg-4">
                        <img src="https://media.istockphoto.com/id/1130934892/photo/doctor-doing-social-service-in-village.jpg?s=612x612&w=0&k=20&c=-YbLWRhnhfzvUxl5fQmpjBuUHVV8KfQJ01WUa7znP5o=" alt="" className="img-fluid rounded-2 mt-4" />
                      </div>
                    </div>
                  </div>
                ) : null
                }

                {tech === 1 ? (

                  <div className="">
                    <div class="row py-5">
                      <div className="col-lg-12">
                        <video controls>
                          <source src="/image/index/video-doctor.mp4" type="video/mp4" />
                          <source src="/image/index/video-doctor.mp4" type="video/ogg" />
                        </video>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>




        {/*  Gallery */}

        <MainFooter />
      </main>
    </>
  );
}

export default Privacy;

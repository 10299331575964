import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import TableColumn from "./dependencies/TableColumn";
import Api from "../../dependencies/utils/Api";
import { FRANCHISE_WALLET_TRACKING } from "../../constants/ApplicationUrl";
import FranchiseWalletModal from "./FranchiseWalletModal";

function FranchiseWalletTracking() {
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
    name: '',
    mobile: '',
    email: '',
    status: '',
    fromDate: '',
    toDate: '',
    orderId: ''
  });
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [balance,setBalance]=useState(0);
  const [showModal, setShowModal] = useState(false);  


  useEffect(() => {
    fetchTransactions();
  }, [query]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await Api.GET(`${FRANCHISE_WALLET_TRACKING}?page=${query.page}&limit=${query.size}&name=${query.name}&fromDate=${query.fromDate}&toDate=${query.toDate}`);
      setTransactions(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotalRecord(response.data.totalTransactions);
      setBalance(response.data.totalRemainingBalance)

    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleClearFilters = () => {
    setQuery({
      page: 1,
      size: 10,
      name: '',
      fromDate: '',
      toDate: '',
    });
  };
  const handleShowModal = () => setShowModal(true);  
  const handleCloseModal = () => setShowModal(false); 
  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Transactions</h4>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="row m-b-20">
              <div className="form-group col-lg-3 mt-3">
                <label>Search By Name</label>
                <input
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  value={query.name}
                  onChange={(e) => setQuery({ ...query, name: e.target.value })}
                />
              </div>

              <div className="form-group col-md-3 mt-3">
                <label>From Date</label>
                <input
                  type="date"
                  name="fromDate"
                  className="form-control"
                  value={query.fromDate}
                  onChange={(e) => setQuery({ ...query, fromDate: e.target.value })}
                />
              </div>
              <div className="form-group col-md-3 mt-3">
                <label>To Date</label>
                <input
                  type="date"
                  name="toDate"
                  className="form-control"
                  value={query.toDate}
                  min={query.fromDate}
                  onChange={(e) => setQuery({ ...query, toDate: e.target.value })}
                />
              </div>

              <div className="col-4">
                <button
                  className="btn btn-sm btn-secondary"
                  style={{ marginTop: "27px", marginRight: "10px" }}
                  onClick={handleClearFilters}
                  type="button"
                >
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 pr-0 bg-white m-1">
              <div className="dash-widget d-flex" onClick={handleShowModal} style={{ cursor: 'pointer' }}>
                <span className="dash-widget-bg2">
                  <i class="fas fa-rupee-sign"></i>
                </span>
                <div
                  className="dash-widget-info float-left pl-2 "
                  style={{ marginLeft: "5px" }}
                >
                  <p>Total Franchise Balance</p>
                  <h4>Rs. {balance}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        pageNo: query.page - 1,
                        size: query.size,
                      })}
                      dataTable={transactions}
                      totalRecord={totalRecord}
                      onPageChange={handlePageChange}
                      onTableChange={() => {}}
                      keyField="transactions._id"
                      sizePerPage={query.size}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FranchiseWalletModal show={showModal} handleClose={handleCloseModal} />

      </div>
    </>
  );
}

export default FranchiseWalletTracking;

import { SET_NOTOFICATION, TOTAL_NOTIFICATION, READ_COUNT, UN_READCOUNT } from "../../constants/actionConstants";

const initialState = {
  NotificationList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTOFICATION:
      return { ...state, NotificationList: action.payload };
    case TOTAL_NOTIFICATION:
      return { ...state, totalNotification: action.payload };
    case READ_COUNT:
      return { ...state, readCount: action.payload };
    case UN_READCOUNT:
      return { ...state, unreadCount: action.payload };
    default:
      return state;
  }
};


import React from "react";
import { Route, Navigate } from "react-router-dom";
import { storage } from "./dependencies/store/storage";

const PrivateRoute = ({ element, ...rest }) => {
  const token = storage.getJwtToken();
  return token ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
